import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import classes from "./OptionList.module.scss.json";
import cx from "classnames";
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import AmountDisplay from '../../../components/AmountDisplay/AmountDisplay';


const TariffPackages = ({tariffPackagesProp, selectedTariffPackage, onTariffPackageChange, tariffFrequency, tariffDisplayMode, bookingPreview}) => {

    const onChangeSelect = (id) =>{
        onTariffPackageChange(id)
    }
    const tariffPackages = React.useMemo(() => {
        return tariffPackagesProp?.sort((a, b) => {
            return a.amount - b.amount
        })
    }, [tariffPackagesProp])

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    
    return (
        <>
            <Row>
                <Col>
                    <p className={"fs20 py-3 medium"}>Kilométrage</p>
                </Col>
            </Row>
            {/* <div className={classes['km-stage-container']}> */}
            <div className={cx("position-relative bradius-5 bg-white p-3")}>
                
                <Row>
                    <Col xs={8} className='d-flex align-items-center'>
                        <div>
                            {bookingPreview?.tariff_package?.unlimited_kilometers ?
                                <span className={"medium"}>Vous disposez d'un kilométrage illimité pour toute votre location.</span>
                            :
                            <span className={"medium"}>Vous disposez de {bookingPreview?.tariff_package?.kilometers ?? ""} kms pour toute votre location. 
                            </span>
                            }
                        </div>
                    </Col>
                    <Col xs={4} className='d-flex justify-content-center align-items-center'>
                        <Dropdown caret={false} direction="down" isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle style={{width: 140, fontSize: 12}} className={cx("btn btn-primary no-caret")} caret={false}>
                                {/* Choisir */}
                                {/* {tariffPackages?.find(tp => tp.id === selectedTariffPackage)?.kilometers === "unlimited" ? "Kilométrage illimité" : tariffPackages?.find(tp => tp.id === selectedTariffPackage)?.kilometers + " kms"} */}
                                {" + "} 
                                <AmountDisplay
                                    tariffDisplayMode={tariffDisplayMode}
                                    invoicingMode={bookingPreview?.invoicing_mode}
                                    exclAmount={selectedTariffPackage ? (tariffPackages?.find(tp =>  tp.id === (selectedTariffPackage ?? bookingPreview?.tariff_package?.id) )?.excl_amount ?? 0) - ( tariffPackages[0]?.excl_amount ?? 0) : 0}
                                    inclAmount={selectedTariffPackage ? (tariffPackages?.find(tp => tp.id === ( selectedTariffPackage ?? bookingPreview?.tariff_package?.id) )?.incl_amount ?? 0) - ( tariffPackages[0]?.incl_amount ?? 0) : 0}
                                    defaultAmount={selectedTariffPackage ? (tariffPackages?.find(tp => tp.id === ( selectedTariffPackage ?? bookingPreview?.tariff_package?.id) )?.amount ?? 0) - ( tariffPackages[0]?.amount ?? 0) : 0}
                                />

                            </DropdownToggle>
                            <DropdownMenu>
                                {/* <DropdownItem header>Sélectionnez un forfait tarifaire</DropdownItem> */}
                                {tariffPackages?.map((tariffPackage) => (
                                    <DropdownItem
                                    className={`c-black ${(selectedTariffPackage === tariffPackage.id || bookingPreview?.tariff_package?.id === tariffPackage.id)  ? 'bg-main-light' : ''}`}
                                        key={tariffPackage.id}
                                        onClick={() => onChangeSelect(tariffPackage.id)}
                                        active={(selectedTariffPackage === tariffPackage.id || bookingPreview?.tariff_package?.id === tariffPackage.id)}
                                        tag="div"
                                    >
                                        
                                        <div>
                                            {tariffPackage?.kilometers === "unlimited" ? "Kilométrage illimité " : tariffPackage?.kilometers + " kms "}
                                            {" + "}
                                            <AmountDisplay
                                                tariffDisplayMode={tariffDisplayMode}
                                                invoicingMode={bookingPreview?.invoicing_mode}
                                                exclAmount={tariffPackage?.excl_amount ? tariffPackage?.excl_amount - tariffPackages[0]?.excl_amount : 0}
                                                inclAmount={tariffPackage?.incl_amount ? tariffPackage?.incl_amount - tariffPackages[0]?.incl_amount : 0}
                                                defaultAmount={tariffPackage?.amount ? tariffPackage?.amount - tariffPackages[0]?.amount : 0}
                                            />
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>


                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TariffPackages
