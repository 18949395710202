const countries = [
    {label:"Afghanistan",code:"af"},
    {label:"Afrique du Sud",code:"za"},
    {label:"Îles Åland",code:"ax"},
    {label:"Albanie",code:"al"},
    {label:"Algérie",code:"dz"},
    {label:"Allemagne",code:"de"},
    {label:"Andorre",code:"ad"},
    {label:"Angola",code:"ao"},
    {label:"Anguilla",code:"ai"},
    {label:"Antarctique",code:"aq"},
    {label:"Antigua-et-Barbuda",code:"ag"},
    {label:"Arabie saoudite",code:"sa"},
    {label:"Argentine",code:"ar"},
    {label:"Arménie",code:"am"},
    {label:"Aruba",code:"aw"},
    {label:"Australie",code:"au"},
    {label:"Autriche",code:"at"},
    {label:"Azerbaïdjan",code:"az"},
    {label:"Bahamas",code:"bs"},
    {label:"Bahreïn",code:"bh"},
    {label:"Bangladesh",code:"bd"},
    {label:"Barbade",code:"bb"},
    {label:"Biélorussie",code:"by"},
    {label:"Belgique",code:"be"},
    {label:"Belize",code:"bz"},
    {label:"Bénin",code:"bj"},
    {label:"Bermudes",code:"bm"},
    {label:"Bhoutan",code:"bt"},
    {label:"Bolivie",code:"bo"},
    {label:"Pays-Bas caribéens",code:"bq"},
    {label:"Bosnie-Herzégovine",code:"ba"},
    {label:"Botswana",code:"bw"},
    {label:"Île Bouvet",code:"bv"},
    {label:"Brésil",code:"br"},
    {label:"Brunei",code:"bn"},
    {label:"Bulgarie",code:"bg"},
    {label:"Burkina Faso",code:"bf"},
    {label:"Burundi",code:"bi"},
    {label:"Îles Caïmans",code:"ky"},
    {label:"Cambodge",code:"kh"},
    {label:"Cameroun",code:"cm"},
    {label:"Canada",code:"ca"},
    {label:"Cap-Vert",code:"cv"},
    {label:"République centrafricaine",code:"cf"},
    {label:"Chili",code:"cl"},
    {label:"Chine",code:"cn"},
    {label:"Île Christmas",code:"cx"},
    {label:"Chypre (pays)",code:"cy"},
    {label:"Îles Cocos",code:"cc"},
    {label:"Colombie",code:"co"},
    {label:"Comores (pays)",code:"km"},
    {label:"République du Congo",code:"cg"},
    {label:"République démocratique du Congo",code:"cd"},
    {label:"Îles Cook",code:"ck"},
    {label:"Corée du Sud",code:"kr"},
    {label:"Corée du Nord",code:"kp"},
    {label:"Costa Rica",code:"cr"},
    {label:"Côte d'Ivoire",code:"ci"},
    {label:"Croatie",code:"hr"},
    {label:"Cuba",code:"cu"},
    {label:"Curaçao",code:"cw"},
    {label:"Danemark",code:"dk"},
    {label:"Djibouti",code:"dj"},
    {label:"République dominicaine",code:"do"},
    {label:"Dominique",code:"dm"},
    {label:"Égypte",code:"eg"},
    {label:"Salvador",code:"sv"},
    {label:"Émirats arabes unis",code:"ae"},
    {label:"Équateur (pays)",code:"ec"},
    {label:"Érythrée",code:"er"},
    {label:"Espagne",code:"es"},
    {label:"Estonie",code:"ee"},
    {label:"États-Unis",code:"us"},
    {label:"Éthiopie",code:"et"},
    {label:"Malouines",code:"fk"},
    {label:"Îles Féroé",code:"fo"},
    {label:"Fidji",code:"fj"},
    {label:"Finlande",code:"fi"},
    {label:"France",code:"fr"},
    {label:"Gabon",code:"ga"},
    {label:"Gambie",code:"gm"},
    {label:"Géorgie (pays)",code:"ge"},
    {label:"Géorgie du Sud-et-les îles Sandwich du Sud",code:"gs"},
    {label:"Ghana",code:"gh"},
    {label:"Gibraltar",code:"gi"},
    {label:"Grèce",code:"gr"},
    {label:"Grenade (pays)",code:"gd"},
    {label:"Groenland",code:"gl"},
    {label:"Guadeloupe",code:"gp"},
    {label:"Guam",code:"gu"},
    {label:"Guatemala",code:"gt"},
    {label:"Guernesey",code:"gg"},
    {label:"Guinée",code:"gn"},
    {label:"Guinée-Bissau",code:"gw"},
    {label:"Guinée équatoriale",code:"gq"},
    {label:"Guyana",code:"gy"},
    {label:"Guyane",code:"gf"},
    {label:"Haïti",code:"ht"},
    {label:"Îles Heard-et-MacDonald",code:"hm"},
    {label:"Honduras",code:"hn"},
    {label:"Hong Kong",code:"hk"},
    {label:"Hongrie",code:"hu"},
    {label:"Île de Man",code:"im"},
    {label:"Îles mineures éloignées des États-Unis",code:"um"},
    {label:"Îles Vierges britanniques",code:"vg"},
    {label:"Îles Vierges des États-Unis",code:"vi"},
    {label:"Inde",code:"in"},
    {label:"Indonésie",code:"id"},
    {label:"Iran",code:"ir"},
    {label:"Irak",code:"iq"},
    {label:"Irlande (pays)",code:"ie"},
    {label:"Islande",code:"is"},
    {label:"Israël",code:"il"},
    {label:"Italie",code:"it"},
    {label:"Jamaïque",code:"jm"},
    {label:"Japon",code:"jp"},
    {label:"Jersey",code:"je"},
    {label:"Jordanie",code:"jo"},
    {label:"Kazakhstan",code:"kz"},
    {label:"Kenya",code:"ke"},
    {label:"Kirghizistan",code:"kg"},
    {label:"Kiribati",code:"ki"},
    {label:"Koweït",code:"kw"},
    {label:"Laos",code:"la"},
    {label:"Lesotho",code:"ls"},
    {label:"Lettonie",code:"lv"},
    {label:"Liban",code:"lb"},
    {label:"Liberia",code:"lr"},
    {label:"Libye",code:"ly"},
    {label:"Liechtenstein",code:"li"},
    {label:"Lituanie",code:"lt"},
    {label:"Luxembourg (pays)",code:"lu"},
    {label:"Macao",code:"mo"},
    {label:"Macédoine du Nord",code:"mk"},
    {label:"Madagascar",code:"mg"},
    {label:"Malaisie",code:"my"},
    {label:"Malawi",code:"mw"},
    {label:"Maldives",code:"mv"},
    {label:"Mali",code:"ml"},
    {label:"Malte",code:"mt"},
    {label:"Îles Mariannes du Nord",code:"mp"},
    {label:"Maroc",code:"ma"},
    {label:"Îles Marshall (pays)",code:"mh"},
    {label:"Martinique",code:"mq"},
    {label:"Maurice (pays)",code:"mu"},
    {label:"Mauritanie",code:"mr"},
    {label:"Mayotte",code:"yt"},
    {label:"Mexique",code:"mx"},
    {label:"États fédérés de Micronésie (pays)",code:"fm"},
    {label:"Moldavie",code:"md"},
    {label:"Monaco",code:"mc"},
    {label:"Mongolie",code:"mn"},
    {label:"Monténégro",code:"me"},
    {label:"Montserrat",code:"ms"},
    {label:"Mozambique",code:"mz"},
    {label:"Birmanie",code:"mm"},
    {label:"Namibie",code:"na"},
    {label:"Nauru",code:"nr"},
    {label:"Népal",code:"np"},
    {label:"Nicaragua",code:"ni"},
    {label:"Niger",code:"ne"},
    {label:"Nigeria",code:"ng"},
    {label:"Niue",code:"nu"},
    {label:"Île Norfolk",code:"nf"},
    {label:"Norvège",code:"no"},
    {label:"Nouvelle-Calédonie",code:"nc"},
    {label:"Nouvelle-Zélande",code:"nz"},
    {label:"Territoire britannique de l'océan Indien",code:"io"},
    {label:"Oman",code:"om"},
    {label:"Ouganda",code:"ug"},
    {label:"Ouzbékistan",code:"uz"},
    {label:"Pakistan",code:"pk"},
    {label:"Palaos",code:"pw"},
    {label:"Palestine",code:"ps"},
    {label:"Panama",code:"pa"},
    {label:"Papouasie-Nouvelle-Guinée",code:"pg"},
    {label:"Paraguay",code:"py"},
    {label:"Pays-Bas",code:"nl"},
    {label:"Pérou",code:"pe"},
    {label:"Philippines",code:"ph"},
    {label:"Îles Pitcairn",code:"pn"},
    {label:"Pologne",code:"pl"},
    {label:"Polynésie française",code:"pf"},
    {label:"Porto Rico",code:"pr"},
    {label:"Portugal",code:"pt"},
    {label:"Qatar",code:"qa"},
    {label:"La Réunion",code:"re"},
    {label:"Roumanie",code:"ro"},
    {label:"Royaume-Uni",code:"gb"},
    {label:"Russie",code:"ru"},
    {label:"Rwanda",code:"rw"},
    {label:"République arabe sahraouie démocratique",code:"eh"},
    {label:"Saint-Barthélemy",code:"bl"},
    {label:"Saint-Christophe-et-Niévès",code:"kn"},
    {label:"Saint-Marin",code:"sm"},
    {label:"Saint-Martin",code:"mf"},
    {label:"Saint-Pierre-et-Miquelon",code:"pm"},
    {label:"Saint-Siège (État de la Cité du Vatican)",code:"va"},
    {label:"Saint-Vincent-et-les-Grenadines",code:"vc"},
    {label:"Sainte-Hélène, Ascension et Tristan da Cunha",code:"sh"},
    {label:"Sainte-Lucie",code:"lc"},
    {label:"Îles Salomon",code:"sb"},
    {label:"Samoa",code:"ws"},
    {label:"Samoa américaines",code:"as"},
    {label:"Sao Tomé-et-Principe",code:"st"},
    {label:"Sénégal",code:"sn"},
    {label:"Serbie",code:"rs"},
    {label:"Seychelles",code:"sc"},
    {label:"Sierra Leone",code:"sl"},
    {label:"Singapour",code:"sg"},
    {label:"Slovaquie",code:"sk"},
    {label:"Slovénie",code:"si"},
    {label:"Somalie",code:"so"},
    {label:"Soudan",code:"sd"},
    {label:"Soudan du Sud",code:"ss"},
    {label:"Sri Lanka",code:"lk"},
    {label:"Suède",code:"se"},
    {label:"Suisse",code:"ch"},
    {label:"Suriname",code:"sr"},
    {label:"Svalbard et ile Jan Mayen",code:"sj"},
    {label:"Eswatini",code:"sz"},
    {label:"Syrie",code:"sy"},
    {label:"Tadjikistan",code:"tj"},
    {label:"Taïwan \/ (République de Chine (Taïwan))",code:"tw"},
    {label:"Tanzanie",code:"tz"},
    {label:"Tchad",code:"td"},
    {label:"Tchéquie",code:"cz"},
    {label:"Terres australes et antarctiques françaises",code:"tf"},
    {label:"Thaïlande",code:"th"},
    {label:"Timor oriental",code:"tl"},
    {label:"Togo",code:"tg"},
    {label:"Tokelau",code:"tk"},
    {label:"Tonga",code:"to"},
    {label:"Trinité-et-Tobago",code:"tt"},
    {label:"Tunisie",code:"tn"},
    {label:"Turkménistan",code:"tm"},
    {label:"Îles Turques-et-Caïques",code:"tc"},
    {label:"Turquie",code:"tr"},
    {label:"Tuvalu",code:"tv"},
    {label:"Ukraine",code:"ua"},
    {label:"Uruguay",code:"uy"},
    {label:"Vanuatu",code:"vu"},
    {label:"Venezuela",code:"ve"},
    {label:"Viêt Nam",code:"vn"},
    {label:"Wallis-et-Futuna",code:"wf"},
    {label:"Yémen",code:"ye"},
    {label:"Zambie",code:"zm"},
    {label:"Zimbabwe",code:"zw"},
]
export default countries;