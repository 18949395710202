import React, {useContext} from 'react';
import {styled} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames"
import classes from "./SearchStepper.scss.json"
import "./SearchStepper.scss";
import { useStyleContext } from '../../../../customHooks/useStyleContext';


const StepperIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        // color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        // color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

const StepperIcon = ({ active, completed, className, icon }) => {

    const stylesContext = useStyleContext();

    let textColor = stylesContext?.colors?.["c-main"] ?? "#000000"

    if(stylesContext?.header?.configs?.["config-app_text_color"]) {
        textColor = stylesContext?.header?.configs?.["config-app_text_color"]
    }

    return (
        <StepperIconRoot ownerState={{active}} className={className} style={{color: textColor}}>
            {completed ? (
                <FontAwesomeIcon icon={faCheck} className={""} />
                // <Check className="QontoStepIcon-completedIcon" />
            ) : ( active ?
                <div className={cx("rounded-circle c-white medium w25 h25 d-flex justify-content-center align-items-center", classes["bg-header-highlight"])}>{icon}</div>
                    :
                <div className={cx("bg-dark bg-opacity-25 rounded-circle c-header-text w25 h25 d-flex justify-content-center align-items-center")}>{icon}</div>
            )}
        </StepperIconRoot>
    );
};

export default StepperIcon;