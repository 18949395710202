import React, { useCallback, useContext, useMemo } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import VehicleSpecItem from "../../components/VehicleSpecItem/VehicleSpecItem";
import cx from "classnames";
import classes from "./VehicleList/VehicleList.scss.json";
import VehiclePicture from "../../components/VehiclePicture/VehiclePicture";
import VehicleDefaultModel from "../../components/VehicleDefaultModel/VehicleDefaultModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import VehiclePictureCarousel from "../../components/VehiclePicture/VehiclePictureCarousel";
import BookingCouponAmount from "../../components/BookingCouponAmount/BookingCouponAmount";
import { useSearch } from '../SearchProvider';
import { useStyleContext } from '../../../customHooks/useStyleContext';
import AmountDisplay from '../../components/AmountDisplay/AmountDisplay';

const BookingRecap = ({ vehicle, bookingPreview, loading = [], couponData, selectedFranchiseLevels, kilometersStage, tariffFrequency }) => {

    const styleContext = useStyleContext();
    const { cookiePriceHasChanged } = useSearch();

    const subtitleMode = useMemo(() => {
        return vehicle?.category?.subtitle_mode ?? "default"
    }, [vehicle]);

    const subtitle = useMemo(() => {
        if (vehicle?.category?.subtitle_mode === "enabled") {
            return vehicle?.category?.custom_subtitle
        } else if (vehicle?.category?.subtitle_mode === "disabled") {
            return ""
        }
        return "ou similaire"
    }, [vehicle]);

    const pictures = useMemo(() => {
        let allPictures = vehicle?.pictures ?? [];

        if (vehicle?.category?.pictures?.length > 0) {
            allPictures = vehicle?.category?.pictures;
        }

        return allPictures;
    }, [vehicle]);

    const bookingTariffModeDisplay = useMemo(() => {
        if (!bookingPreview) return null;
        let tariffDisplayMode = bookingPreview?.tariff_display_mode;
        if (!tariffDisplayMode) { tariffDisplayMode = "pricing_group" }
        switch (tariffDisplayMode) {
            case 'pricing_group':
                return bookingPreview?.invoicing_mode === 'ht' ? "HT" : "TTC";
            case 'ht':
                return "HT";
            default:
                return "TTC";
        }
    }, [bookingPreview]);

    return (
        <div className={"sticky-top bg-white bradius-5 d-flex flex-column mb-5"}>
            <hr className={"w-75 my-3 mx-auto"} />
            <Row className={"mt-3 px-3"}>
                {cookiePriceHasChanged && (
                    <div className=' alert alert-warning w-100' role='alert'>
                        <p className='text-center'>Le prix de votre réservation a changé.</p>
                        <br />
                        <p className='text-center'>Veuillez vérifier les détails de votre réservation avant de continuer.</p>
                    </div>
                )}
                <Col xs={6}>
                    <p className={classes.bolder + " fs18"}>Votre sélection</p>
                </Col>
                <Col xs={6}>
                    {pictures?.length > 1 ? (
                        <VehiclePictureCarousel
                            pictures={pictures}
                            id={`vehicle_${vehicle?.id}`}
                            vehicleType={vehicle?.category?.vehicle_type}
                            height={100}
                        />
                    ) : (
                        <VehiclePicture
                            className={"w-100 bradius-5"}
                            // url={pictures?.[0]?.url ?? vehicle?.picture_url}
                            url={styleContext?.app_config?.vehicle_search_result && vehicle?.picture_url ? vehicle?.picture_url : (pictures?.[0]?.url ?? vehicle?.picture_url)}
                            vehicleType={vehicle?.category?.vehicle_type}
                            height={100}
                        />
                    )}

                </Col>
            </Row>
            <Row className='px-3'>
                <p className={"mt-4"}>
                    <span className={"bold fs18"}><VehicleDefaultModel vehicle={vehicle} /></span>
                    {subtitleMode !== 'disabled' && <span className={"fst-italic fs14"}>&nbsp;{subtitle}</span>}
                </p>
            </Row>
            <Row className={"mt-2 px-3"}>
                {vehicle?.category?.search_result_format === "custom" ? (
                    <Row className={"mt-2"}>
                        {vehicle?.category?.custom_vehicle_details?.map((detail, index) => {
                            return <Col xs={12} key={`custom_detail_item_${index}_${detail?.id}`}><VehicleSpecItem websiteStyle={styleContext} iconName={detail?.icon ?? "ac"} label={detail.label ?? ""} /></Col>
                        })}
                    </Row>
                ) : (
                    <>
                        <Col xs={12}>
                            <span className={classes.bolder + " my-2 fs16"}>Équipements</span>
                        </Col>
                        {vehicle?.nb_places > 0 && (
                            <Col xs={6}>
                                <VehicleSpecItem websiteStyle={styleContext} iconName={"seat"} label={`${vehicle.nb_places} places`} />
                            </Col>
                        )}
                        {vehicle?.specificities?.gps && (
                            <Col xs={6}>
                                <VehicleSpecItem websiteStyle={styleContext} iconName={"ac"} label={"Climatisation"} />
                            </Col>
                        )}
                        {vehicle?.energy && (
                            <Col xs={6}>
                                <VehicleSpecItem websiteStyle={styleContext} iconName={"gas"} label={vehicle?.energy} />
                            </Col>
                        )}
                        {vehicle?.nb_doors > 0 && (
                            <Col xs={6}>
                                <VehicleSpecItem websiteStyle={styleContext} iconName={"door"} label={`${vehicle.nb_doors} portes`} />
                            </Col>
                        )}
                        {vehicle?.gearbox && (
                            <Col xs={6}>
                                <VehicleSpecItem websiteStyle={styleContext} iconName={"shifter"} label={vehicle.gearbox} />
                            </Col>
                        )}
                        {/*{vehicle?.nb_place && (<Col xs={6}><VehicleSpecItem iconName={"bags"} label={"1 bagage"}/></Col>*/}
                    </>
                )}
            </Row>

            <Row className='mt-4 fs14 px-3'>
                <span className={classes.bolder + " my-2 fs16"}>Informations complémentaires</span>

                <div className={"d-flex justify-content-between mt-1"}>
                    <p className={"medium"}>Durée</p>
                    <p className={classes.bolder}>{bookingPreview?.nb_days} jour{bookingPreview?.nb_days > 1 && 's'}</p>
                </div>

                {(selectedFranchiseLevels?.length > 0 || bookingPreview?.option_pack_amount?.franchise_level || bookingPreview?.option_pack_amount?.franchise_levels) && (
                    <>
                        {bookingPreview?.option_pack_amount?.franchise_level && (
                            <>
                                <div className={"d-flex justify-content-between mt-1"}>
                                    <p className={"regular"}>Montant de la franchise</p>
                                    {loading?.length ? <>...</> : (
                                        <p className={classes.bolder}>{`${bookingPreview?.option_pack_amount?.franchise_level?.franchise_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                    )}
                                </div>
                            </>
                        )}
                        {bookingPreview?.option_pack_amount?.franchise_levels && (
                            <>
                                {bookingPreview?.option_pack_amount?.franchise_levels?.map(franchiseLevel => {
                                    return <div
                                        key={`franchise-level-recap-mobile-${franchiseLevel?.id}`}>
                                        <div className={"d-flex justify-content-between mt-1"}>
                                            <p className={"regular"}>
                                                {franchiseLevel?.name ?
                                                    <span>Franchise : <span className='bold'>{franchiseLevel?.name}</span> <span className='fs10'>({franchiseLevel?.franchise_amount} {styleContext?.currency?.symbol ?? ''})</span></span>
                                                    :
                                                    <span>Montant de Franchise</span>
                                                }
                                            </p>
                                            {loading?.length ? <>...</> : (
                                                <p className={classes.bolder}>{`${bookingPreview?.franchise_level_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                            )}
                                        </div>
                                    </div>
                                })}
                            </>
                        )}
                        {(selectedFranchiseLevels?.length > 0) && (
                            <>

                                {selectedFranchiseLevels?.map(franchiseLevel => {
                                    return <div
                                        key={`franchise-level-recap-mobile-${franchiseLevel?.id}`}>
                                        <div className={"d-flex justify-content-between mt-1"}>
                                            <p className={"regular"}>
                                                {franchiseLevel?.franchise_level_type?.name ? (
                                                    <span>Franchise : <span className='bold'>{franchiseLevel?.franchise_level_type.name} ({franchiseLevel?.franchise_amount} {styleContext?.currency?.symbol ?? ''})</span></span>
                                                )
                                                    :
                                                    <span>Montant de Franchise</span>
                                                }
                                            </p>
                                            {loading?.length ? <>...</> : (
                                                <p className={classes.bolder}>{`${bookingPreview?.franchise_level_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                            )}
                                        </div>
                                    </div>
                                })}
                            </>
                        )}
                    </>
                )}
                {bookingPreview?.deposit && (
                    <div className={"d-flex justify-content-between mt-1"}>
                        <p className={"regular"}>Montant de la caution</p>
                        {loading?.length ? <>...</> : (
                            <p className={classes.bolder}>{`${bookingPreview?.deposit?.amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                        )}
                    </div>
                )}
                {(kilometersStage || bookingPreview?.tariff_package?.kilometers || bookingPreview?.option_pack_amount?.kilometers_stage) &&
                    <div className={"d-flex justify-content-between mt-1"}>
                        <p className={"medium"}>Kilométrage inclus</p>
                        {loading?.length ? <>...</> : (
                            bookingPreview?.tariff_package ? (
                                <p className={classes.bolder}>{bookingPreview?.tariff_package?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.tariff_package?.kilometers} km`} </p>
                            ) : (
                                bookingPreview?.option_pack_amount?.kilometers_stage ? (
                                    <>
                                        {bookingPreview?.option_pack_amount?.kilometers_stage?.kilometer_type == "total" ?
                                            <p className={classes.bolder}>{bookingPreview?.option_pack_amount?.kilometers_stage?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.option_pack_amount?.kilometers_stage?.kilometers_per_day} km`} </p>
                                            :
                                            <p className={classes.bolder}>{bookingPreview?.option_pack_amount?.kilometers_stage?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.option_pack_amount?.kilometers_stage?.kilometers_per_day} km/${tariffFrequency}`} </p>
                                        }
                                    </>
                                ) : (
                                    <>
                                    {kilometersStage?.kilometer_type == "total" ? 
                                    <p className={classes.bolder}>{kilometersStage?.unlimited_kilometers ? "Illimité" : `${kilometersStage?.kilometers_per_day} km`} </p>
                                    :
                                    <p className={classes.bolder}>{kilometersStage?.unlimited_kilometers ? "Illimité" : `${kilometersStage?.kilometers_per_day} km/${tariffFrequency}`} </p>
                                    }
                                    </>
                                )
                            )
                        )}
                    </div>
                }
            </Row>

            <Row className='mt-4 fs14 px-3'>
                <span className={classes.bolder + " my-2 fs16"}>Récapitulatif du montant</span>
                {bookingPreview?.pricing_group && bookingPreview?.pricing_group.public_name && (
                    <div id={"group-" + bookingPreview?.pricing_group.id} className={cx(classes["pricing-group"])}>
                        <p className={"medium"}>Tarification client appliquée : </p>
                        <p className={classes.bolder}>{bookingPreview?.pricing_group.public_name}</p>
                    </div>
                )}
                <div className={"d-flex justify-content-between mt-2"}>
                    <p className={"medium"}>Montant <span className={"fs12 fst-italic"}>(hors option)</span></p>
                    <p className={classes.bolder}>
                        <AmountDisplay
                            tariffDisplayMode={bookingPreview?.tariff_display_mode}
                            invoicingMode={bookingPreview?.invoicing_mode}
                            inclAmount={bookingPreview?.incl_amount}
                            exclAmount={bookingPreview?.excl_amount}
                            defaultAmount={bookingPreview?.amount}
                        />
                    </p>
                </div>
                {bookingPreview?.products?.length > 0 && bookingPreview.products.filter((p) => p.type === "kilometers_stage").map((product) => (
                    <div key={`product-recap-${product.id}`} className={"d-flex justify-content-between mt-1"}>
                        <p className={"medium"}>{product.name} 
                            <span className='fs11 bold'>(
                                <AmountDisplay
                                    tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                    invoicingMode={bookingPreview?.invoicing_mode}
                                    exclAmount={kilometersStage?.excl_price_per_day ?? 0}
                                    inclAmount={kilometersStage?.incl_price_per_day ?? 0}
                                    defaultAmount={kilometersStage?.price_per_day ?? 0}
                                />
                                {kilometersStage?.kilometer_type != "total" && tariffFrequency ? (' / ' + tariffFrequency) : ''})</span>
                            </p>
                        <p className={classes.bolder}>
                            <AmountDisplay
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                invoicingMode={bookingPreview?.invoicing_mode}
                                exclAmount={product?.amount}
                                inclAmount={product.incl_amount}
                            />
                        </p>
                    </div>
                ))}

                {bookingPreview?.option_amount_details?.cdw_option && (
                    <div className={"d-flex justify-content-between mt-3"}>
                        <p className={"medium"}>{bookingPreview?.option_amount_details?.cdw_option?.option_name}</p>
                        {loading.length ? <>...</> : <p className={classes.bolder}>{`${bookingPreview?.option_amount_details?.cdw_option?.amount} ${styleContext?.currency?.symbol ?? ''}`}</p>}
                    </div>
                )}

                {bookingPreview?.option_pack_amount?.name && (
                    <div className={"d-flex justify-content-between mt-3"}>
                        <p className={"medium"}>Package : {bookingPreview?.option_pack_amount?.name}</p>
                        {loading?.length ? <>...</> : <p className={classes.bolder}>
                            <AmountDisplay
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                invoicingMode={bookingPreview?.invoicing_mode}
                                inclAmount={bookingPreview?.option_pack_amount?.incl_amount}
                                exclAmount={bookingPreview?.option_pack_amount?.excl_amount}
                                defaultAmount={bookingPreview?.option_pack_amount?.amount}
                            />
                        </p>}
                    </div>
                )}

                {(selectedFranchiseLevels?.length > 0) && (bookingPreview?.franchise_level_amount != null) && (bookingPreview?.franchise_level_amount > 0) && (
                    <div className={"d-flex justify-content-between mt-1"}>
                        <p className={"medium"}>Rachat de franchise</p>
                        {loading?.length ? <>...</> : <p className={classes.bolder}>
                            <AmountDisplay
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                invoicingMode={bookingPreview?.invoicing_mode}
                                inclAmount={bookingPreview?.franchise_level_incl_amount}
                                exclAmount={bookingPreview?.franchise_level_excl_amount}
                                defaultAmount={bookingPreview?.franchise_level_amount}
                            />
                        </p>}
                    </div>
                )}

                <div className={"d-flex flex-column justify-content-between mt-1"}>
                    <p className={"medium"}>Options</p>
                    {!bookingPreview?.option_pack_amount?.options.length && !bookingPreview?.option_amount_details?.options?.length && (
                        <p className={"fst-italic c-grey text-center"}>Aucune option</p>
                    )}
                    <div style={{ marginLeft: '0.5em' }}>
                        {!bookingPreview?.option_pack_amount?.options.length > 0 && !bookingPreview?.option_pack_amount?.options.map((option) => (
                            <div key={`option-pack-recap-${option.option_id}`} className={"d-flex justify-content-between"}>
                                <p className={"regular"}>{option.option_name}</p>
                                {loading.length ? <>...</> : <p className={"light fst-italic c-main"}>Inclus</p>}
                            </div>
                        ))}
                        {bookingPreview?.option_amount_details?.options?.length > 0 && bookingPreview?.option_amount_details?.options.map((option) => (
                            <div key={`option-recap-${option.option_id}`} className={"d-flex justify-content-between"}>
                                <span className={"bold fs14"}>
                                    {option.option_name}
                                    {option.amount > 0 ? (
                                        option.type == 'per_day' ? (
                                            <span className={"regular fs12"} style={{ marginLeft: '0.5em' }}>{option.amount / bookingPreview.nb_days} {styleContext?.currency?.symbol ?? ''} / jour</span>
                                        ) : (
                                            <span className={"regular fs12"} style={{ marginLeft: '0.5em' }}>{option.amount} {styleContext?.currency?.symbol ?? ''} x {option.quantity}</span>
                                        )
                                    ) : (
                                        <span className={"regular fs12"} style={{ marginLeft: '0.5em' }}>inclus</span>
                                    )}
                                </span>
                                {loading.length ? <>...</> : <p className={classes.bolder}>
                                    <AmountDisplay
                                        tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                        invoicingMode={bookingPreview?.invoicing_mode}
                                        inclAmount={option.incl_amount * option?.quantity}
                                        exclAmount={option.excl_amount * option?.quantity}
                                        defaultAmount={option.amount * option?.quantity}
                                    />
                                </p>}
                            </div>
                        ))}
                    </div>
                </div>
            </Row>

            {couponData?.code && <>
                <div className={" d-flex justify-content-between align-items-center mt-4 border bradius-5 p-5"}>
                    <div className={"d-flex align-items-center"}>
                        <FontAwesomeIcon className={"me-2"} icon={faTag} />
                        <p><span
                            className={"medium"}>{couponData?.code ?? couponData?.name} {couponData.type === "percentage" && <>(-{parseInt(couponData?.value)} %)</>}</span>
                        </p>
                    </div>
                    {loading?.length ? <>...</> : (
                        <BookingCouponAmount
                            className={"regular fst-italic"}
                            couponAmount={bookingPreview?.coupon_amount}
                            couponType={couponData?.type}
                            invoicingMode={bookingPreview?.invoicing_mode}
                            vatRate={bookingPreview?.vat_rate}
                            currencySymbol={styleContext?.currency?.symbol}
                        />
                    )}
                </div>
            </>}

            <div id={cx(classes.total_amount)}>
                <div className={"d-flex justify-content-between"}>
                    <span>Montant TOTAL</span>
                    {loading?.length ? <>...</> : (<p>
                        <AmountDisplay
                            tariffDisplayMode={bookingPreview?.tariff_display_mode}
                            invoicingMode={bookingPreview?.invoicing_mode}
                            exclAmount={bookingPreview?.total_excl_amount}
                            inclAmount={bookingPreview?.total_incl_amount}
                            defaultAmount={bookingPreview?.total_amount}
                            showTTC={bookingTariffModeDisplay === 'TTC'}
                        />
                    </p>)}
                </div>
                {loading?.length ? <>...</> : (
                    bookingTariffModeDisplay === 'TTC' ?
                        <p>dont {parseFloat(bookingPreview?.vat_amount).toFixed(2)} {styleContext?.currency?.symbol ?? ''} de taxes incluses (TVA)</p>
                        :
                        <p>soit <span className={"bold"}>{parseFloat(bookingPreview?.total_incl_amount).toFixed(2)} {styleContext?.currency?.symbol ?? ''}</span> TTC</p>
                )}
            </div>

            {styleContext?.app_config?.booking_conditions?.length > 0 && (
                <div className={"bg-white p-2 my-3"}>
                    <p className={"medium fs14 underline mb-1"}>Conditions de réservation :</p>
                    <div className={"bg-white p-2"}>
                        {styleContext?.app_config?.booking_conditions?.map((conditionItem, index) => {
                            return <p key={`booking-condition-text-${index}`} className={"fs14"}>- {conditionItem}</p>
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingRecap;
