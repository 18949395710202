import React, {useContext, useEffect, useMemo, useState} from 'react';
import VehicleItem from "../components/VehicleList/VehicleItem";
import OptionList from "../components/OptionList/OptionList";
import CdwList from "../components/OptionList/CdwList";
import OptionPacks from "../components/OptionList/OptionPacks";
import {Link} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";

import cx from "classnames";
import {IconButton, Paper, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import apiService from "../../../service/api";
import sandbox from "../../../service/Sandbox";
import FranchiseLevelList from "../components/OptionList/FranchiseLevelList";
import KilometersStagesList from "../components/OptionList/KilometersStagesList";
import { useSearch } from '../SearchProvider';
import { useStyleContext } from '../../../customHooks/useStyleContext';
import AmountDisplay from '../../components/AmountDisplay/AmountDisplay';
import TariffPackages from '../components/OptionList/TariffPackages';

const Options = ({
    vehicle,
    setStepHeader,
    selectedOptions,
    setSelectedOptions,
    selectedPackOption,
    setSelectedPackOption,
    options,
    packOptions,
    bookingPreview,
    couponData,
    setCouponData,
    franchiseLevels,
    selectedFranchiseLevel,
    selectedFranchiseLevelIds,
    onFranchiseLevelChange,
    kilometersStages,
    selectedKilometersStage,
    onKilometersStageChange,
    formDataLoading,
    tariffFrequency,
    formData,
    tariffDisplayMode,
    showOptionImage,
    tariffPackages,
    selectedTariffPackage,
    onTariffPackageChange,
    setSelectedTariffPackage
}) => {

    const styleContext = useStyleContext();
    const { bookingPreviewLoading } = useSearch();

    const [couponState, setCouponState] = useState({
        loading: false,
        error: null,
        code: couponData?.code ?? ""
    })

    const sortedFranchiseLevels = useMemo(() => {
        return franchiseLevels?.sort((a, b) => {

            if(!a?.franchise_level_type || !b?.franchise_level_type){
                return 0
            }

            if(a?.franchise_level_type?.name === b?.franchise_level_type?.name){
                return b?.franchise_amount -  a?.franchise_amount
            }
            // sort by franchise level type name
            return a?.franchise_level_type?.name?.localeCompare(b?.franchise_level_type?.name)


            // return b?.franchise_amount - a?.franchise_amount
        }) ?? []
    }, [franchiseLevels]);

    useEffect(() => {
        setStepHeader(1)
        window.scrollTo(0, 0)
        document.getElementById("option-page-title").scrollIntoView({behavior: 'auto',  block: "start"})
    }, [])

    useEffect(() => {
        const preSelectOptions = options?.filter(option => option?.mandatory_on_web === true)

        preSelectOptions?.forEach(option => {
            changeOption(option.id, 1)
        })
    }, [options])

    const changeOption = (optionId, quantity) => {
        if (quantity < 0) {return}
        let optionQuantity = quantity
        let limit = options.find(o => o.id === optionId)?.limit_per_vehicle || 1
        if (quantity > limit){
            optionQuantity = limit
        }

        setSelectedOptions(prevState => {
            let newSelected = prevState?.filter(option => option?.option_id !== optionId)
            if (quantity > 0){
                newSelected?.push({option_id: optionId, quantity: optionQuantity})
            }
            return newSelected
        })
    }

    const handlePackOptionSelect = (optionsPackId) => {
        if (selectedPackOption === optionsPackId){
            setSelectedPackOption(null)
        } else {
            console.log("select pack option")
            const packOption = packOptions.find(item => item.id === optionsPackId);
            if(packOption) {
                setSelectedOptions(prevState => {
                    console.log(prevState)
                    const res = prevState.filter((option) => {
                        let filterRes = true
                        packOption.options?.forEach((item) => {
                            if(item.id === option.option_id) {
                                filterRes = false
                            }
                        })
                        return filterRes
                    })
                    console.log(res)
                    return res
                })
            }
            setSelectedPackOption(optionsPackId)
            onFranchiseLevelChange(null)
        }
    }

    const handleCouponErrorReset = () => {
        setCouponState(prevState => {
            return {...prevState, error: null}
        })
    }
    const handleCouponCodeChange = (value) => {
        setCouponState(prevState => {
            return {...prevState, code: value}
        })
    }
    const applyCouponCode = () => {
        const data = {
            coupon_code: couponState?.code,
        }
        if (bookingPreview?.nb_days) {
            data.nb_days = bookingPreview?.nb_days
        }
        if (bookingPreview?.seasons) {
            data.seasons = bookingPreview?.seasons
        }
        if (bookingPreview?.start_agency) {
            data.agency = bookingPreview?.start_agency
        }
        setCouponState(prevState => {
            return {...prevState, loading: true}
        })
        apiService.get(`/api/coupon`, data).then((res) => {
            setCouponState(prevState => {
                return {...prevState, loading: false}
            })
            if(res.coupon) {
                setCouponData && setCouponData(res.coupon)
                setCouponState(prevState => {
                    return {...prevState, error: null}
                })
            } else {
                setCouponData && setCouponData({})
                setCouponState(prevState => {
                    return {...prevState, error: "Ce code promo n'est pas disponible."}
                })
            }
        }).catch((e) => {
            setCouponState(prevState => {
                return {...prevState, loading: false}
            })
        })
    }

    return (
        <>
            <div className={"px-3"}>
                {vehicle &&
                    <>
                        <Row>
                            <Col xs={12} >
                                <VehicleItem vehicle={vehicle} selected/>
                            </Col>
                        </Row>
                        {vehicle.description && styleContext?.app_config?.vehicle_search_result && (
                            <Row className={"border bradius-5 mt-2 bg-white"}>
                                <Col xs={12} >
                                    <p className={"fs16 py-2"} style={{whiteSpace: "pre-wrap"}}>{vehicle.description}</p>
                                </Col>
                            </Row>
                        )}
                        {tariffPackages?.length > 1 &&
                            <TariffPackages
                                tariffPackagesProp={tariffPackages}
                                selectedTariffPackage={selectedTariffPackage}
                                onTariffPackageChange={onTariffPackageChange}
                                tariffFrequency={tariffFrequency}
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                bookingPreview={bookingPreview}
                            />
                        }
                        {(!formDataLoading && packOptions.length > 0) &&
                            <OptionPacks
                                packOptions={packOptions}
                                changeOptionPack={handlePackOptionSelect}
                                category={vehicle.category}
                                selectedPackOption={selectedPackOption}
                                formDataLoading={formDataLoading}
                                bookingPreview={bookingPreview}
                                tariffFrequency={tariffFrequency}
                                // tariffDisplayMode={tariffDisplayMode}
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                            />
                        }
                        {(bookingPreview?.option_pack_amount?.franchise_level || bookingPreview?.option_pack_amount?.franchise_levels)  ? (
                            <Row className={"mt-4"}>
                                <Col xs={12} >
                                    <div className={cx("position-relative border bg-white p-3")}>
                                        {bookingPreview?.option_pack_amount?.franchise_level && (
                                            <Row>
                                                <Col xs={8}>
                                                    <div>
                                                        <span className={"medium"}>Franchise de {bookingPreview?.option_pack_amount?.franchise_level?.franchise_amount ?? ""}{styleContext?.currency?.symbol ?? ''}</span>
                                                    </div>
                                                </Col>
                                                <Col xs={4} className={"d-flex justify-content-center align-items-center"}>
                                                    <p className={"c-main medium"}>
                                                        <FontAwesomeIcon icon={faCheck} color={styleContext?.colors?.['c-main']} /> Inclus dans le pack
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                        {bookingPreview?.option_pack_amount?.franchise_levels && (
                                            bookingPreview?.option_pack_amount?.franchise_levels?.map((item, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <Col xs={8}>
                                                            <div>
                                                                <span className={"medium"}>{item?.name ? <span>Franchise {item?.name} </span> : <span>Franchise </span>}de {item?.franchise_amount ?? ""}{styleContext?.currency?.symbol ?? ''}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} className={"d-flex justify-content-center align-items-center"}>
                                                            <p className={"c-main medium"}>
                                                                <FontAwesomeIcon icon={faCheck} color={styleContext?.colors?.['c-main']} /> Inclus dans le pack
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            franchiseLevels?.length > 0 &&
                                <FranchiseLevelList
                                    franchiseLevels={sortedFranchiseLevels}
                                    selectedFranchiseLevel={selectedFranchiseLevel}
                                    onFranchiseLevelChange={onFranchiseLevelChange}
                                    tariffFrequency={tariffFrequency}
                                    selectedFranchiseLevelIds={selectedFranchiseLevelIds}
                                    tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                />
                        )}
                        {(bookingPreview?.option_pack_amount?.kilometers_stage && bookingPreview?.pricing_type !== "tariff_package") ? (
                            <Row className={"mt-4"}>
                                <Col xs={12} >
                                    <div className={cx("position-relative border bg-white p-3")}>
                                        <Row>
                                            <Col xs={8}>
                                                <div>
                                                    {bookingPreview?.option_pack_amount?.kilometers_stage?.unlimited_kilometers ?
                                                        <span className={"medium"}>Vous disposez d'un kilométrage illimité.</span>
                                                    :
                                                    <span className={"medium"}>Vous disposez de {bookingPreview?.option_pack_amount?.kilometers_stage?.kilometers_per_day * bookingPreview?.nb_days ?? ""} kms pour votre location. <span className='fs14'>({bookingPreview?.option_pack_amount?.kilometers_stage?.kilometers_per_day ?? ""} kms x {bookingPreview?.nb_days ?? ""} {tariffFrequency}{(bookingPreview?.nb_days > 1 && tariffFrequency !== 'mois') ? 's' : ''})</span></span>
                                                    }
                                                </div>
                                            </Col>
                                            <Col xs={4} className={"d-flex justify-content-center align-items-center"}>
                                                <p className={"c-main medium"}>
                                                    <FontAwesomeIcon icon={faCheck} color={styleContext?.colors?.['c-main']} /> Inclus dans le pack
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        ):(kilometersStages?.length > 0  && bookingPreview?.pricing_type !== "tariff_package") &&
                            <KilometersStagesList
                                kilometersStages={kilometersStages}
                                selectedKilometersStage={selectedKilometersStage}
                                onKilometersStageChange={onKilometersStageChange}
                                tariffFrequency={tariffFrequency}
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                            />
                        }
                        {options.length > 0 &&
                            <OptionList
                                options={options}
                                setSelectedOptions={setSelectedOptions}
                                selectedOptions={selectedOptions}
                                vehicle={vehicle}
                                changeOption={changeOption}
                                selectedPackOption={selectedPackOption}
                                packOptions={packOptions}
                                tariffFrequency={tariffFrequency}
                                formData={formData}
                                showOptionImage={showOptionImage}
                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                            />
                        }
                        <>

                            <Row className={"align-items-center mt-4"}>
                                <Col>
                                    <Paper className={"p-3"}>
                                        <Row>
                                            <Col>
                                                <p className={"fs20 pb-3 medium"}>Code Promo</p>
                                            </Col>
                                        </Row>
                                        <Row className={"align-items-center"}>
                                            <Col md={6}>
                                                <div className={"d-flex align-items-center w-100"}>
                                                    <TextField
                                                        className={"me-2 no-border w-100"}
                                                        size={"small"}
                                                        // label={"Code"}
                                                        variant={'outlined'}
                                                        value={couponState?.code}
                                                        onChange={(e) => handleCouponCodeChange(e.target.value)}
                                                        name={"coupon_code"}
                                                    />
                                                    <Button
                                                        onClick={applyCouponCode}
                                                        disabled={couponState?.loading}
                                                    >
                                                        Utiliser
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                {couponState?.error && <div className={"bradius-5 d-flex align-items-center justify-content-center bg-warning-light p-2"}>
                                                    <p className={"text-center me-1"}>{couponState.error}</p>
                                                    <IconButton onClick={handleCouponErrorReset}><FontAwesomeIcon icon={faTimes} /></IconButton>
                                                </div>}
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Col>
                            </Row>
                        </>

                    </>
                }
                {styleContext?.app_config?.booking_conditions?.length > 0 && (
                    <div className={"bg-white p-2 my-3"}>
                        <p className={"medium fs14 underline mb-1"}>Conditions de réservation :</p>
                        <div className={"bg-white p-2"}>
                            {styleContext?.app_config?.booking_conditions?.map( (conditionItem, index) => {
                                return <p key={`booking-condition-text-${index}`} className={"fs14"}>- {conditionItem}</p>
                            }) }
                        </div>
                    </div>
                )}
            </div>
            {vehicle &&
                <div className={"mt-4 mb-5 text-end px-3 py-md-0 pt-1"}>
                    {bookingPreviewLoading.length > 0 ? (<>
                            <Button
                                className={cx({"d-none d-md-inline-block" : !sandbox.inIframe()})}
                                disabled
                            >
                                <span className={"c-white"}>Mise à jour des données en cours...</span>
                            </Button>
                            {!sandbox.inIframe() && (
                                <div className={"d-block d-md-none bg-white "}>
                                    <Button className={"w-100 fixed-bottom h50 shadow"} variant={"primary"}>Mise à jour des données en cours...</Button>
                                </div>
                            )}
                    </>) : (
                        <Link to={"/infos-client"} className={"mt-2 mb-5 d-inline-block"}>
                            <div className={cx({"d-none d-md-block" : !sandbox.inIframe()})}>
                                <Button>
                                    <span className={"c-white"}>Continuer {bookingPreview?.total_amount != null &&
                                        <>pour <span className={"no-tr"}>
                                            <AmountDisplay
                                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                                invoicingMode={bookingPreview?.invoicing_mode}
                                                exclAmount={bookingPreview?.total_excl_amount}
                                                inclAmount={bookingPreview?.total_incl_amount}
                                                defaultAmount={bookingPreview?.total_amount}
                                            />
                                        </span></>}
                                    </span>
                                </Button>
                            </div>
                            {!sandbox.inIframe() && (
                                <div className={"d-block d-md-none bg-white fixed-bottom bg-light"}>
                                    <Button className={"w-100 fixed-bottom h50 shadow"} variant={"primary"}>
                                        <span className={"bold"}>Continuer {bookingPreview?.total_amount != null &&
                                            <>pour <span className={"no-tr"}>
                                                <AmountDisplay
                                                    tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                                    invoicingMode={bookingPreview?.invoicing_mode}
                                                    exclAmount={bookingPreview?.total_excl_amount}
                                                    inclAmount={bookingPreview?.total_incl_amount}
                                                    defaultAmount={bookingPreview?.total_amount}
                                                />
                                            </span> </>}
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </Link>
                    )}
                </div>
            }
        </>
    );
};

export default Options;