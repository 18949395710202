import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Col, Row } from "react-bootstrap";
import classes from "./CustomerForm.module.scss.json"
import "./CustomerForm.module.scss"
import cx from "classnames"
import { TextField } from "@mui/material";

import { useForm } from "react-hook-form";
import Loader from "../../../components/Loader/Loader";
import sandbox from "../../../../service/Sandbox";
import * as Sentry from "@sentry/react";

import MuiPhoneNumber from 'material-ui-phone-number';
import MuiCountrySelect from "../../../components/MuiCountrySelect/MuiCountrySelect";
import { DatePicker } from "@mui/lab";
import { format, isValid } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSyncAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useCustomer } from '../../../../customHooks/useCustomer';
import { useSearch } from '../../SearchProvider';
import { useStyleContext } from '../../../../customHooks/useStyleContext';

const defaultFieldNames = ["birth_date", "birth_place", "license_number", "license_date", "license_country"]
const CustomerForm = ({ customerData, onChange, customerLoading, onSaveBooking, bookingPreview, customFieldValues, onBirthDateChange }) => {

    const styleContext = useStyleContext();
    const customerContext = useCustomer();
    const { bookingPreviewLoading, handleCustomerProOffline, customerProOffline } = useSearch();

    const { isLog, customer, setOpenModalLogin, openModalLogin, openModalRecovery, isEnabled, isPro, company } = customerContext;

    const [state, setState] = useState();
    const [drivers, setDrivers] = useState([])
    const [bookingFieldValues, setBookingFieldValues] = useState({})

    const [validPhoneNumber, setValidPhoneNumber] = useState(false)
    const [showErrorPhoneNumber, setShowErrorPhoneNumber] = useState(false)
    const { handleSubmit, formState: { errors } } = useForm();
    const [hasValidBirthdayDate, setHasValidBirthdayDate] = useState(false)
    const [hasValidLicenseDate, setHasValidLicenseDate] = useState(false)
    const [userMailExist, setUserMailExist] = React.useState(null);
    const [checkUserMailExist, setCheckUserMailExist] = React.useState(false);
    const [createCustomerAccount, setCreateCustomerAccount] = React.useState(false);

    const [showDriverSurchargeInfo, setShowDriverSurchargeInfo] = useState(false)

    const defaultFieldSettings = useMemo(() => {

        const result = {}
        styleContext?.app_config?.custom_fields?.forEach(item => {
            if (defaultFieldNames.includes(item.name)) {
                result[item.name] = item
            }
        })
        return result
    }, []);

    const linkColor = useMemo(() => {
        let color = "#000000"
        if (styleContext?.colors?.['c-link'] && styleContext?.colors?.['c-link'] !== "") {
            if (styleContext?.colors?.['c-link']?.toLowerCase() !== "#ffffff") {
                color = styleContext?.colors?.['c-link']
            }
        }
        return color
    }, [styleContext]);

    const showRows = useMemo(() => {
        const result = { birth: true, licence: true }

        if (defaultFieldSettings?.birth_date && defaultFieldSettings?.birth_place) {
            if (!defaultFieldSettings?.birth_date?.enabled && !defaultFieldSettings?.birth_place?.enabled) {
                result.birth = false
            }
        }
        if (defaultFieldSettings?.license_number && defaultFieldSettings?.license_date && defaultFieldSettings?.license_country) {
            if (!defaultFieldSettings?.license_number?.enabled && !defaultFieldSettings?.license_date?.enabled && !defaultFieldSettings?.license_country?.enabled) {
                result.licence = false
            }
        }
        return result
    }, [defaultFieldSettings]);

    const customFields = useMemo(() => {
        return styleContext?.app_config?.custom_fields?.filter(item => !defaultFieldNames.includes(item.name))
    }, []);

    const customTexts = useMemo(() => {
        return styleContext?.app_config?.custom_texts
    }, []);

    useEffect(() => {
        if (userMailExist !== 2) { return; }

        const delay = setTimeout(() => {
            if (openModalLogin) { return; }

            setOpenModalLogin(true);
        }, 2000);

        return () => clearTimeout(delay);
    }, [userMailExist]);

    useEffect(() => {
        if (!openModalRecovery) { return; }

        setOpenModalLogin(false);
    }, [openModalRecovery]);

    useEffect(() => {
        if (userMailExist !== null) {
            setUserMailExist(null);
        }

        if (isLog) {
            setState(isPro ? company : customer);

            return;
        }

        if (customerData) {
            setState(customerData)
        }
    }, [isLog]);

    useEffect(() => {
        const phoneNumber = ((isLog && isPro) || customerProOffline) ? state?.company_phone_number : state?.phone_number;

        if (phoneNumber) {
            const validatePhoneNumberRegex = /^\+?[1-9][0-9]{7,14}$/;
            const isValid = validatePhoneNumberRegex.test(phoneNumber);
            setValidPhoneNumber(isValid)
            if (isValid) {
                setShowErrorPhoneNumber(false)
            }
        }
    }, [state?.company_phone_number, state?.phone_number])

    useEffect(() => {
        let hasDriverSurcharge = false
        bookingPreview?.option_amount_details?.options?.forEach(item => {
            if (item.code_option === "additional_drivers") {
                setDrivers(sandbox.range(0, item.quantity, 1).map(i => ({})))
            }
            if (item?.is_young_driver_surcharge) {
                hasDriverSurcharge = true
            }
        })
        setShowDriverSurchargeInfo(hasDriverSurcharge)
    }, [bookingPreview]);

    useEffect(() => {
        const newFieldVals = {}
        styleContext?.app_config?.custom_fields?.forEach(item => {
            if (item.enabled) {
                newFieldVals[item.name] = ""
            }
        })
        setBookingFieldValues(newFieldVals)
    }, [styleContext?.app_config?.custom_fields])


    useEffect(() => {
        onBirthDateChange(state?.birthday_date)
    }, [state?.birthday_date]);

    useEffect(() => {
        checkUserMail();
    }, [customerProOffline]);

    const handleDateChange = ({ name, value, dateFormat = 'yyyy-MM-dd' }) => {
        if (name == "birthday") {
            if (value === null) {
                setHasValidBirthdayDate(true)
            } else {
                setHasValidBirthdayDate(!isValid(value))
            }
        }
        if (name == "license_date") {
            if (value === null) {
                setHasValidLicenseDate(true)
            } else {
                setHasValidLicenseDate(!isValid(value))
            }
        }
        if (isValid(value)) {
            setState(prevState => {
                return {
                    ...prevState,
                    [`${name}_date`]: value,
                    [name]: format(value, dateFormat)
                }
            })
            onChange && onChange({
                ...state,
                [`${name}_date`]: value,
                [name]: format(value, dateFormat)
            })
        }
    }

    const handleChange = ({ name, value }, type = 'customer') => {
        setState(prevState => {
            return { ...prevState, [name]: value }
        })
        onChange && onChange({ ...state, [name]: value }, type)
    }

    const handleCustomFieldChange = ({ name, value }) => {
        setBookingFieldValues(prevState => {
            return { ...prevState, [name]: value }
        })
        if (!defaultFieldNames.includes(name)) {
            onChange && onChange({ ...bookingFieldValues, [name]: value }, 'custom_field')
        }
    }

    const handleCreateCustomerAccount = async () => {
        if (!state?.email && !state?.company_email) { throw new Error('Email is required') }

        const data = JSON.parse(JSON.stringify(state));

        if (customerProOffline) {
            data.email = state?.company_email;

            delete data.company_email;
        }
        
        try {
            const req = await fetch('/api/customer/register', {
                method: 'POST',
                contentType: 'application/json',
                body: JSON.stringify(data)
            });

            if (req.status !== 200) {
                throw new Error('Error while creating customer account');
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const handleBookingSave = () => {
        if (createCustomerAccount) {
            try {
                (async () => await handleCreateCustomerAccount())();
            } catch (error) {
                if (error == "Error while creating customer account") {
                    Sentry.captureException(error);
                    return;
                }

                alert('Merci de remplir votre adresse email');

                return;
            }
        }

        if (validPhoneNumber) {
            onSaveBooking && onSaveBooking({ withDrivingLicence: showRows.licence })
        } else {
            document.getElementById((isLog && isPro) || customerProOffline ? "company-phone-number" : "phone-number").focus()
            setShowErrorPhoneNumber(true)
        }
    }


    const handleDriverUpdate = (index, { name, value }) => {
        const updatedDrivers = drivers
        setDrivers(prevState => {
            const updatedDrivers = [...prevState]
            updatedDrivers[index] = { ...updatedDrivers[index], [name]: value }
            return updatedDrivers
        })
        onChange && onChange({ ...state, drivers: updatedDrivers })
    }

    const handleDriverDateUpdate = (index, { name, value, dateFormat }) => {
        if (isValid(value)) {
            const updatedDrivers = drivers
            setDrivers(prevState => {
                const updatedDrivers = sandbox.deepClone([...prevState])
                updatedDrivers[index] = {
                    ...updatedDrivers[index],
                    [`${name}_date`]: value,
                    [name]: format(value, dateFormat)
                }
                onChange && onChange({ ...state, drivers: updatedDrivers })
                return updatedDrivers
            })
        }
    }

    const handleSwitchCustomer = (e) => {
        if (isLog) { return; }
        
        setUserMailExist(null);
        handleCustomerProOffline(e.target.checked);
    };

    const checkUserMail = async () => {
        if (!isEnabled) { return; }
        if (!state?.email && !state?.company_email) { return; }
        if (state?.email == '' && state?.company_email == '') { return; }

        const pattern = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

        if (customerProOffline && !pattern.test(state.company_email)) { return; }
        if (!customerProOffline && !pattern.test(state.email)) { return; }

        setCheckUserMailExist(true);

        try {
            const req = await fetch('/api/customer/check-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: customerProOffline ? state?.company_email : state?.email })
            });

            if (req.status === 200) {
                const res = await req.json();

                /**
                 * @code 0: customer not found (can register)
                 * @code 1: customer found but no account (auto send link to reset password)
                 * @code 2: customer found and account found (can login)
                 */
                setUserMailExist(parseInt(res.code));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setCheckUserMailExist(false);
        }
    }

    return (
        <div className={"mb-4"}>
            <Row className='my-1'>
                <Col>
                    {isLog ? (
                        <p className={"bold fs20"}>Finalisez votre réservation</p>
                    ) : (
                        <p className={"bold fs20"}>Saisissez vos informations personnelles</p>
                    )}
                </Col>
            </Row>
            {isEnabled && (isLog ? (
                <Row className='mt-3'>
                    <span className={"fs16"}><FontAwesomeIcon icon={faUserCircle} /> Compte : {customer.first_name + ' ' + customer.last_name?.toUpperCase()}<a className={classes["custom-link"]} onClick={() => window.location.href = '/utilisateur/deconnexion'}>Se déconnecter</a></span>
                </Row>
            ) : (
                <Row className='mt-3'>
                    <span className={"fs16"}>Vous avez déjà un compte ? <a className={classes["custom-link"]} onClick={() => setOpenModalLogin(true)}>Connectez-vous</a></span>
                </Row>
            ))}
            <div className={classes['custom-form-switch'] + ' mt-4'}>
                <label className="form-check-label" htmlFor="switch-customer">Particulier</label>
                <div className={"form-switch"}>
                    <input className="form-check-input" type="checkbox" role="switch" id="switch-customer" checked={(customerProOffline || (isLog && isPro))} data-checked={customerProOffline.toString()} onChange={handleSwitchCustomer} disabled={isLog} />
                </div>
                <label className="form-check-label" htmlFor="switch-customer">Professionnel</label>
            </div>
            {userMailExist === 2 && (
                <Row className={"mt-3 " + classes["custom-alert"]}>
                    <p>Un compte client a été trouvé avec cette adresse email</p>
                    <p>Veuillez vous connecter en utilisant vos identifiants.</p>
                </Row>
            )}
            <form onSubmit={handleSubmit(handleBookingSave)} className='mt-2'>
                {((isLog && isPro) || customerProOffline) ? (
                    <CompanySearchFormData
                        state={state}
                        handleChange={(change) => handleChange(change, 'company')}
                        handleCustomFieldChange={handleCustomFieldChange}
                        bookingFieldValues={bookingFieldValues}
                        customerLoading={customerLoading}
                        customFields={customFields}
                        customTexts={customTexts}
                        styleContext={styleContext}
                        userMailExist={userMailExist}
                        checkUserMail={checkUserMail}
                        checkUserMailExist={checkUserMailExist}
                        isLog={isLog}
                        isEnabled={isEnabled}
                        bookingPreviewLoading={bookingPreviewLoading}
                        linkColor={linkColor}
                        resetUserMailExist={() => {
                            setUserMailExist(null);
                            handleChange({ name: 'company_email', value: '' }, 'company');
                        }}
                        createCustomerAccount={setCreateCustomerAccount}
                    />
                ) : (
                    <CustomerSearchFormData
                        state={state}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        handleCustomFieldChange={handleCustomFieldChange}
                        bookingFieldValues={bookingFieldValues}
                        customerLoading={customerLoading}
                        customFields={customFields}
                        customTexts={customTexts}
                        showRows={showRows}
                        styleContext={styleContext}
                        userMailExist={userMailExist}
                        checkUserMail={checkUserMail}
                        checkUserMailExist={checkUserMailExist}
                        isLog={isLog}
                        isEnabled={isEnabled}
                        bookingPreviewLoading={bookingPreviewLoading}
                        showDriverSurchargeInfo={showDriverSurchargeInfo}
                        showErrorPhoneNumber={showErrorPhoneNumber}
                        validPhoneNumber={validPhoneNumber}
                        defaultFieldSettings={defaultFieldSettings}
                        linkColor={linkColor}
                        hasValidBirthdayDate={hasValidBirthdayDate}
                        hasValidLicenseDate={hasValidLicenseDate}
                        resetUserMailExist={() => {
                            setUserMailExist(null);
                            handleChange({ name: 'email', value: '' });
                        }}
                        createCustomerAccount={setCreateCustomerAccount}
                    />
                )}
            </form>
        </div>
    );
};

const CustomerSearchFormData = ({
    state,
    handleChange,
    handleDateChange,
    handleCustomFieldChange,
    bookingFieldValues,
    customerLoading,
    customFields,
    customTexts,
    showRows,
    styleContext,
    userMailExist,
    checkUserMail,
    checkUserMailExist,
    isLog,
    isEnabled,
    bookingPreviewLoading,
    showDriverSurchargeInfo,
    showErrorPhoneNumber,
    validPhoneNumber,
    defaultFieldSettings,
    linkColor,
    hasValidBirthdayDate,
    hasValidLicenseDate,
    resetUserMailExist,
    createCustomerAccount
}) => {

    const hasntAccount = (userMailExist === 0 || userMailExist === 1);

    return (
        <div className={cx(classes.form, "px-0")}>
            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor="email-address-input" className="col-form-label fs14">Adresse email*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id="email-address-input"
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"email"}
                        type={"email"}
                        required
                        value={state?.email ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        disabled={isLog || (userMailExist !== null && userMailExist !== 0)}
                        onBlur={() => {

                            if (state?.email && state.email != '') {
                                checkUserMail();
                            }
                        }}
                        title={isLog ? "Vous ne pouvez pas modifier votre email" : ""}
                    />
                </Col>
                {(userMailExist !== null && userMailExist !== 0) && (
                    <Col sm={'auto'}>
                        <span className={classes['fake-button']} onClick={resetUserMailExist}>Changer d'adresse email</span>
                    </Col>
                )}
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor={"first-name"} className="col-form-label fs14">Prénom*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"first-name"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"first_name"}
                        required
                        value={state?.first_name ?? ''}
                        onChange={(e) => handleChange(e.target)}
                    />
                </Col>
                <Col sm={2}>
                    <label htmlFor={"last-name"} className="col-form-label fs14 text-start w-100">Nom*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"last-name"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"last_name"}
                        value={state?.last_name ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor={"address"} className="col-form-label fs14">Adresse*</label>
                </Col>
                <Col sm={10} >
                    <TextField
                        id={"address"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"address"}
                        value={state?.address ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor={"post-code"} className="col-form-label fs14">Code postal*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"post-code"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"postcode"}
                        type={'text'}
                        value={state?.postcode ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
                <Col sm={2}>
                    <label htmlFor="city" className="col-form-label fs14">Ville*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"city"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"city"}
                        value={state?.city ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor="country" className="col-form-label fs14">Pays*</label>
                </Col>
                <Col sm={4}>
                    <MuiCountrySelect
                        size={"small"}
                        className={"w-100"}
                        label={''}
                        defaultValue={"fr"}
                        required
                        onChange={(country) => handleChange({ name: 'country', value: country?.code?.toUpperCase() ?? '' })}
                    />
                </Col>
                <Col sm={2}>
                    <label htmlFor="phone-number" className="col-form-label fs14">Téléphone*</label>
                </Col>
                <Col sm={4}>
                    <MuiPhoneNumber
                        className={"form-control-plaintext"}
                        id={"phone-number"}
                        defaultCountry={'fr'}
                        onChange={(value) => handleChange({ name: 'phone_number', value })}
                        variant={"outlined"}
                        size={"small"}
                        autoFormat={false}
                        required
                        value={state?.phone_number ?? ''}
                        error={showErrorPhoneNumber && !validPhoneNumber}
                        helperText={showErrorPhoneNumber ? "Numéro de téléphone invalide" : ""}
                    />
                </Col>
            </Row>

            {showRows.birth && (
                <Row className={"align-items-center mb-2"}>
                    {(!defaultFieldSettings?.birth_date || defaultFieldSettings?.birth_date?.enabled) && (
                        <>
                            <Col sm={2}>
                                <label htmlFor="birthday" className="col-form-label fs14">Date de naissance {defaultFieldSettings?.birth_date?.required && '*'}</label>
                            </Col>
                            <Col sm={4} >
                                <DatePicker
                                    className={"form-control-plaintext"}
                                    value={state?.birthday_date ?? ''}
                                    inputFormat={"dd/MM/yyyy"}
                                    mask={"__/__/____"}
                                    onChange={(newValue) => handleDateChange({ name: 'birthday', value: newValue, dateFormat: 'yyyy-MM-dd' })}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        size={"small"}
                                        className={'w-100'}
                                        variant={"outlined"}
                                        error={hasValidBirthdayDate}
                                        required={defaultFieldSettings?.birth_date?.required ?? false}
                                    />}
                                />
                            </Col>
                            {showDriverSurchargeInfo &&
                                <Col sm={6} ><p>Une surcharge s’applique pour cette tranche d’âge</p></Col>
                            }
                        </>
                    )}
                    {(!defaultFieldSettings?.birth_place || defaultFieldSettings?.birth_place?.enabled) && (
                        <>
                            <Col sm={2}>
                                <label htmlFor="birth-city" className="col-form-label fs14">Lieu de naissance {defaultFieldSettings?.birth_place?.required && '*'}</label>
                            </Col>
                            <Col sm={4}>
                                <TextField
                                    id="birth-city"
                                    className={"form-control-plaintext"}
                                    variant={"outlined"}
                                    size={"small"}
                                    margin={"dense"}
                                    name={"birth_city"}
                                    value={state?.birth_city ?? ''}
                                    onChange={(e) => handleChange(e.target)}
                                    required={defaultFieldSettings?.birth_place?.required ?? false}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            )}

            {showRows.licence && (
                <>
                    <Row className={"align-items-center mb-2 mt-4"}>
                        <Col xs={12}>
                            <p className={"medium"}>Permis de conduire</p>
                        </Col>
                    </Row>
                    <Row className={"align-items-center mb-2"}>
                        {(!defaultFieldSettings?.license_number || defaultFieldSettings?.license_number?.enabled) && (
                            <>
                                <Col sm={2}>
                                    <label htmlFor="license-number" className="col-form-label fs14">N° de permis {defaultFieldSettings?.license_number?.required && '*'}</label>
                                </Col>
                                <Col sm={4} className={"mb-2"}>
                                    <TextField
                                        id="license-number"
                                        className={"form-control-plaintext"}
                                        variant={"outlined"}
                                        size={"small"}
                                        margin={"dense"}
                                        required={defaultFieldSettings?.license_number?.required ?? false}
                                        name={"license_number"}
                                        value={state?.license_number ?? ''}
                                        onChange={(e) => handleChange(e.target)}
                                    />
                                </Col>
                            </>
                        )}
                        {(!defaultFieldSettings?.license_date || defaultFieldSettings?.license_date?.enabled) && (
                            <>
                                <Col sm={2}>
                                    <label htmlFor="license-date" className="col-form-label fs14">Date d‘obtention {defaultFieldSettings?.license_date?.required && '*'}</label>
                                </Col>
                                <Col sm={4}>
                                    <DatePicker
                                        id={"license-date"}
                                        className={"form-control-plaintext"}
                                        value={state?.license_date_date ?? ''}
                                        inputFormat={"dd/MM/yyyy"}
                                        // mask={"__/__/____"}
                                        onChange={(newValue) => handleDateChange({ name: 'license_date', value: newValue, dateFormat: 'yyyy-MM-dd' })}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            size={"small"}
                                            className={'w-100'}
                                            variant={"outlined"}
                                            required={defaultFieldSettings?.license_date?.required ?? false}
                                            error={hasValidLicenseDate}
                                        />}
                                    />
                                </Col>
                            </>
                        )}
                        {(!defaultFieldSettings?.license_country || defaultFieldSettings?.license_country?.enabled) && (
                            <>
                                <Col sm={2}>
                                    <label htmlFor="license-country" className="col-form-label fs14">Pays d‘obtention {defaultFieldSettings?.license_country?.required && '*'}</label>
                                </Col>
                                <Col sm={4} className={"mb-2"}>
                                    <MuiCountrySelect
                                        size={"small"}
                                        className={"w-100"}
                                        label={''}
                                        required={defaultFieldSettings?.license_country?.required ?? false}
                                        onChange={(country) => handleChange({ name: 'license_country', value: country?.code?.toUpperCase() ?? '' })}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </>
            )}
            {customFields?.length > 0 && (
                <>
                    <Row className={"align-items-center mb-2 mt-4"}>
                        <Col xs={12}>
                            <p className={"medium"}>Information complémentaire</p>
                        </Col>
                    </Row>

                    <Row className={"align-items-center mb-2"}>
                        {customFields?.map((field) => {
                            if (field.enabled) {
                                return <React.Fragment key={field?.name}>
                                    <Col key={`custom_field_${field?.name}`} sm={2}>
                                        <label htmlFor={`field-${field?.name}`} className="col-form-label fs14">{field?.label}{field?.required && '*'}</label>
                                    </Col>
                                    <Col sm={4}>
                                        <TextField
                                            id={`field-${field?.name}`}
                                            className={"form-control-plaintext"}
                                            variant={"outlined"}
                                            size={"small"}
                                            margin={"dense"}
                                            required={field?.required}
                                            InputProps={{ inputProps: { maxLength: 255 } }}
                                            name={field?.name}
                                            value={bookingFieldValues?.[field?.name]}
                                            onChange={(e) => handleCustomFieldChange(e.target)}
                                        />
                                    </Col>
                                </React.Fragment>
                            }
                        })}
                    </Row>
                </>
            )}
            <div>
                {customTexts?.length > 0 && <>
                    {customTexts?.map((text) => {
                        return text?.enabled && <p key={`custom_text_${text?.name}`}>{text?.content}</p>
                    })}
                </>}
            </div>
            <div className="form-check form-check-inline mt-5 mb-3">
                <input name="cgv_check" className="form-check-input" type="checkbox" id="cgv-check" required />
                <label className="form-check-label" htmlFor="cgv-check">J’accepte
                    {(styleContext?.cgv_cgl_url && styleContext?.cgv_cgl_url !== "") ? <a
                        target="_blank"
                        className={"underline"}
                        style={{ color: `${linkColor}!important` }}
                        href={`${styleContext?.cgv_cgl_url}`}
                        ref={el => el && el.style.setProperty("color", linkColor, "important")}
                    > les conditions générales de vente</a> : <span> les conditions générales de vente</span>}.
                </label>
            </div>
            {hasntAccount && !isLog && (
                <div className="form-check">
                    <input name="customer_account_check" className="form-check-input" type="checkbox" id="customer-account-check" onChange={(e) => createCustomerAccount(e.target.checked)} />
                    <label className="form-check-label" htmlFor="customer-account-check">Je souhaite créer mon Espace Client. Cela me permettra d'accéder à ma liste de locations et mes documents personnels.</label>
                </div>
            )}
            <div className={"text-center mt-4"}>
                {isEnabled ? (
                    (checkUserMailExist || bookingPreviewLoading.length > 0) ? (
                        <button type="button" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={true}>
                            <span>Mise à jour des données en cours...</span>
                        </button>
                    ) : (
                        (userMailExist === null && !isLog) ? (
                            <span className='fs14'>Veuillez remplir tous les champs requis</span>
                        ) : (
                            (hasntAccount || isLog) ? (
                                <button type="submit" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={customerLoading}>
                                    {customerLoading ? <Loader /> : <span>{styleContext?.booking_module_setting?.booking_mode === "manual" ? "Envoyer ma demande" : "Réserver"}</span>}
                                </button>
                            ) : (
                                <span className='fs14'>Veuillez vous connecter en utilisant vos identifiants pour procéder à la {styleContext?.booking_module_setting?.booking_mode === "manual" ? "demande de" : ""} réservation</span>
                            )
                        )
                    )
                ) : (
                    bookingPreviewLoading.length > 0 ? (
                        <button type="button" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={true}>
                            <span>Mise à jour des données en cours...</span>
                        </button>
                    ) : (
                        <button type="submit" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={customerLoading}>
                            {customerLoading ? <Loader /> : <span>{styleContext?.booking_module_setting?.booking_mode === "manual" ? "Envoyer ma demande" : "Réserver"}</span>}
                        </button>
                    )
                )}
            </div>
        </div>
    );
};

const CompanySearchFormData = ({
    state,
    handleChange,
    handleDateChange,
    handleCustomFieldChange,
    bookingFieldValues,
    customerLoading,
    customFields,
    customTexts,
    showRows,
    styleContext,
    userMailExist,
    checkUserMail,
    checkUserMailExist,
    isLog,
    isEnabled,
    bookingPreviewLoading,
    showDriverSurchargeInfo,
    showErrorPhoneNumber,
    validPhoneNumber,
    defaultFieldSettings,
    linkColor,
    hasValidBirthdayDate,
    hasValidLicenseDate,
    resetUserMailExist,
    createCustomerAccount
}) => {

    const hasntAccount = (userMailExist === 0 || userMailExist === 1);

    return (
        <div className={cx(classes.form, "px-0")}>
            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor={"company-email"} className="col-form-label fs14">Adresse email*</label>
                </Col>
                <Col sm={4} className='d-flex flex-row align-items-center'>
                    <TextField
                        id={"company-email"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_email"}
                        type={"email"}
                        value={state?.company_email ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                        disabled={isLog || (userMailExist !== null && userMailExist !== 0)}
                        onBlur={() => {

                            if (state?.company_email && state.company_email != '') {
                                checkUserMail();
                            }
                        }}
                    />
                </Col>
                {(userMailExist !== null && userMailExist !== 0) && (
                    <Col sm={'auto'}>
                        <span className={classes['fake-button']} onClick={resetUserMailExist}>Changer d'adresse email</span>
                    </Col>
                )}
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor="company-name" className="col-form-label fs14">Nom*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id="company-name"
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_name"}
                        value={state?.company_name ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
                <Col sm={2}>
                    <label htmlFor="phone-number" className="col-form-label fs14">Téléphone*</label>
                </Col>
                <Col sm={4}>
                    <MuiPhoneNumber
                        className={"form-control-plaintext"}
                        id={"company-phone-number"}
                        defaultCountry={'fr'}
                        onChange={(value) => handleChange({ name: 'company_phone_number', value })}
                        variant={"outlined"}
                        size={"small"}
                        autoFormat={false}
                        required
                        value={state?.company_phone_number ?? ''}
                        error={showErrorPhoneNumber && !validPhoneNumber}
                        helperText={showErrorPhoneNumber ? "Numéro de téléphone invalide" : ""}
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor="company-siren" className="col-form-label fs14">SIREN*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id="company-siren"
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_siren"}
                        value={state?.company_siren ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
                <Col sm={2}>
                    <label htmlFor={"company-vat-number"} className="col-form-label fs14">N° TVA*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"company-vat-number"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_vat_number"}
                        value={state?.company_vat_number ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor={"company-address"} className="col-form-label fs14">Adresse*</label>
                </Col>
                <Col sm={10} >
                    <TextField
                        id={"company-address"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_address"}
                        value={state?.company_address ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor={"company-post-code"} className="col-form-label fs14">Code postal*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"company-post-code"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_postcode"}
                        type={'text'}
                        value={state?.company_postcode ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
                <Col sm={2}>
                    <label htmlFor="company-city" className="col-form-label fs14">Ville*</label>
                </Col>
                <Col sm={4}>
                    <TextField
                        id={"company-city"}
                        className={"form-control-plaintext"}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        name={"company_city"}
                        value={state?.company_city ?? ''}
                        onChange={(e) => handleChange(e.target)}
                        required
                    />
                </Col>
            </Row>

            <Row className={"align-items-center mb-2"}>
                <Col sm={2}>
                    <label htmlFor="company-country" className="col-form-label fs14">Pays*</label>
                </Col>
                <Col sm={4}>
                    <MuiCountrySelect
                        size={"small"}
                        className={"w-100"}
                        label={''}
                        defaultValue={"fr"}
                        required
                        onChange={(country) => handleChange({ name: 'company_country', value: country?.code?.toUpperCase() ?? '' })}
                    />
                </Col>
            </Row>

            {customFields?.length > 0 && (
                <>
                    <Row className={"align-items-center mb-2 mt-4"}>
                        <Col xs={12}>
                            <p className={"medium"}>Information complémentaire</p>
                        </Col>
                    </Row>

                    <Row className={"align-items-center mb-2"}>
                        {customFields?.map((field) => {
                            if (field.enabled) {
                                return <React.Fragment key={field?.name}>
                                    <Col key={`custom_field_${field?.name}`} sm={2}>
                                        <label htmlFor={`field-${field?.name}`} className="col-form-label fs14">{field?.label}{field?.required && '*'}</label>
                                    </Col>
                                    <Col sm={4}>
                                        <TextField
                                            id={`field-${field?.name}`}
                                            className={"form-control-plaintext"}
                                            variant={"outlined"}
                                            size={"small"}
                                            margin={"dense"}
                                            required={field?.required}
                                            InputProps={{ inputProps: { maxLength: 255 } }}
                                            name={field?.name}
                                            value={bookingFieldValues?.[field?.name]}
                                            onChange={(e) => handleCustomFieldChange(e.target)}
                                        />
                                    </Col>
                                </React.Fragment>
                            }
                        })}
                    </Row>
                </>
            )}
            <div>
                {customTexts?.length > 0 && <>
                    {customTexts?.map((text) => {
                        return text?.enabled && <p key={`custom_text_${text?.name}`}>{text?.content}</p>
                    })}
                </>}
            </div>
            <div className="form-check form-check-inline mt-5 mb-3">
                <input name="cgv_check" className="form-check-input" type="checkbox" id="cgv-check" required />
                <label className="form-check-label" htmlFor="cgv-check">J’accepte
                    {(styleContext?.cgv_cgl_url && styleContext?.cgv_cgl_url !== "") ? <a
                        target="_blank"
                        className={"underline"}
                        style={{ color: `${linkColor}!important` }}
                        href={`${styleContext?.cgv_cgl_url}`}
                        ref={el => el && el.style.setProperty("color", linkColor, "important")}
                    > les conditions générales de vente</a> : <span> les conditions générales de vente</span>}.
                </label>
            </div>
            {hasntAccount && !isLog && (
                <div className="form-check">
                    <input name="customer_account_check" className="form-check-input" type="checkbox" id="customer-account-check" onChange={(e) => createCustomerAccount(e.target.checked)} />
                    <label className="form-check-label" htmlFor="customer-account-check">Je souhaite créer mon Espace Client. Cela me permettra d'accéder à ma liste de locations et mes documents personnels.</label>
                </div>
            )}
            <div className={"text-center mt-4"}>
                {isEnabled ? (
                    (checkUserMailExist || bookingPreviewLoading.length > 0) ? (
                        <button type="button" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={true}>
                            <span>Mise à jour des données en cours...</span>
                        </button>
                    ) : (
                        userMailExist === null && !isLog ? (
                            <span className='fs14'>Veuillez remplir tous les champs requis</span>
                        ) : (
                            (hasntAccount || isLog) ? (
                                <button type="submit" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={customerLoading}>
                                    {customerLoading ? <Loader /> : <span>{styleContext?.booking_module_setting?.booking_mode === "manual" ? "Envoyer ma demande" : "Réserver"}</span>}
                                </button>
                            ) : (
                                <span className='fs14'>Veuillez vous connecter en utilisant vos identifiants pour procéder à la {styleContext?.booking_module_setting?.booking_mode === "manual" ? "demande de" : ""} réservation</span>
                            )
                        )
                    )
                ) : (
                    bookingPreviewLoading.length > 0 ? (
                        <button type="button" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={true}>
                            <span>Mise à jour des données en cours...</span>
                        </button>
                    ) : (
                        <button type="submit" className={cx("btn btn-primary bg-main px-4", classes.book_btn)} disabled={customerLoading}>
                            {customerLoading ? <Loader /> : <span>{styleContext?.booking_module_setting?.booking_mode === "manual" ? "Envoyer ma demande" : "Réserver"}</span>}
                        </button>
                    )
                )}
            </div>
        </div>
    );
};

export default CustomerForm;