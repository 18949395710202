import React, {useContext, useEffect, useMemo, useState} from 'react';
import classes from "./OptionList.module.scss.json";
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {Divider} from "@mui/material";
import { useStyleContext } from '../../../../customHooks/useStyleContext';
import AmountDisplay from "../../../components/AmountDisplay/AmountDisplay";

const OptionPackItem = ({optionPack, selectedPackId, onClick, category, bookingPreview, tariffFrequency, tariffDisplayMode}) => {

    const styleContext = useStyleContext()

    const [selected, setSelected] = useState(false);
    const [amount, setAmount] = useState(optionPack?.default_amount ?? 0)
    const [priceType, setPriceType] = useState("total")
    const [categoryPrice, setCategoryPrice] = useState(null)

    const packageContent = useMemo(() => {
        const result = {
            franchise_level : null,
            franchise_levels : null,
            kilometers_stage : null,
            deposit: null,
        }
        if(optionPack?.kilometers_stage) {
            result.kilometers_stage = {
                kilometers_per_day :  optionPack?.kilometers_stage?.unlimited_kilometers ? "Illimité" : `${optionPack?.kilometers_stage?.kilometers_per_day} km/${tariffFrequency}`,
            }
        }
        if(optionPack?.franchise_level) {
            result.franchise_level = {
                franchise_amount : optionPack?.franchise_level?.franchise_amount,
                // deposit_amount : optionPack?.franchise_level?.deposit_amount,
            }
        }
        if(optionPack?.franchise_levels) {
            result.franchise_levels = optionPack?.franchise_levels?.map((item) => ({
                franchise_amount : item?.franchise_amount,
                name: item?.franchise_level_type?.name ?? "",
            }))
        }
        if(optionPack?.category_deposit) {
            result.deposit = {
                deposit_amount : optionPack?.category_deposit?.amount,
            }
        }
        console.log({result})

        return result
    }, [optionPack]);

    useEffect( () => {
        setSelected(() => {
            return optionPack?.id === selectedPackId
        })
    }, [selectedPackId])

    useEffect(() => {
        if(category) {
            let categoryPrice = optionPack?.pack_option_category_price?.find((item) => item?.category?.id === category.id)
            if(categoryPrice) {
                setCategoryPrice(categoryPrice)
            } else {
                setCategoryPrice(null)
            }
        }
    }, [])

    useEffect(() => {
        if(categoryPrice) {
            setAmount(categoryPrice?.amount)
            setPriceType(categoryPrice.type)
        } else {
            setAmount(optionPack?.default_amount)
            setPriceType(optionPack?.price_type)
        }
    }, [categoryPrice])

    const handlePackSelect = () => {
        onClick && onClick(optionPack?.id)
    }

    return (
        <div className={"d-flex flex-column h-100"}>
            <div className={classes.highlights}>
                {optionPack?.highlights?.length > 0 &&
                    <>
                        <div className={cx(" c-white rounded-pill text-center w-100 d-flex justify-content-center align-items-center", classes.text)}>{optionPack?.highlights?.[0]?.value}</div>
                        <div className={classes.triangle}></div>
                    </>
                }
            </div>

            <div className={cx("d-flex flex-column flex-fill mb-3 bradius-5 border bg-white mb-4 mb-lg-0 pointer", {[classes.pack_option_selected] : selected})} onClick={() => handlePackSelect()}>
                <div className={"d-flex justify-content-center align-items-center p-3"}>
                    <div className={"uppercase c-main bold fs20"}>{optionPack?.name}</div>
                </div>
                <div className={"pb-4 px-4 flex-fill d-flex flex-column justify-content-between"}>
                    <div className={"mb-4"}>
                        <p className={"medium fs14"}>
                            <span>Prix du pack :
                                {amount > 0 ? (
                                    <>
                                        <span className={"fs18"}>
                                            <AmountDisplay
                                                tariffDisplayMode={tariffDisplayMode}
                                                invoicingMode={optionPack?.pricing_group_invoicing_mode}
                                                exclAmount={optionPack.excl_default_amount}
                                                inclAmount={optionPack.incl_default_amount}
                                                defaultAmount={optionPack.default_amount}
                                            />
                                        </span>
                                        {priceType === 'per_day' ? <span>/{tariffFrequency}</span> : <></>}
                                    </>
                                ) : (
                                    <span className={"ms-2 c-main fst-italic"}>Inclus</span>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className={cx("mb-2", classes.option_pack_details)}>
                        {optionPack?.details?.map((item, index) => {
                            return <p key={`option_pac_detail_${index}_${optionPack.id}`} className={"medium fs12"}>
                                <FontAwesomeIcon icon={faCheck} color={styleContext?.colors?.['c-main']} className={"me-2"}/><span>{item?.value}</span>
                            </p>
                        })}
                    </div>
                    <div>
                        {(packageContent?.franchise_level || packageContent?.kilometers_stage || packageContent?.deposit || packageContent.franchise_levels) && (
                            <>
                                <Divider className={"mt-3 mb-1"} />
                                {packageContent?.franchise_level && (
                                    <>
                                        <p className={"fs12 medium"}>Montant de la franchise : {packageContent?.franchise_level?.franchise_amount}{styleContext?.currency?.symbol ?? ''}</p>
                                        {/*<p className={"fs12 medium"}>Montant de la caution : {packageContent?.franchise_level?.deposit_amount}{styleContext?.currency?.symbol ?? ''}</p>*/}
                                    </>
                                )}
                                {packageContent?.franchise_levels && (
                                    <>
                                        {packageContent?.franchise_levels?.map((item, index) => {
                                            return <p key={`franchise_level_${index}_${optionPack.id}`} className={"fs12 medium"}>Franchise {item?.name} : {item?.franchise_amount ?? ""}{styleContext?.currency?.symbol ?? ''}</p>
                                        })}
                                    </>
                                )}
                                {(packageContent?.deposit) && (
                                    <>
                                        <p className={"fs12 medium"}>Caution : {packageContent?.deposit?.deposit_amount}{styleContext?.currency?.symbol ?? ''}</p>
                                    </>
                                )}
                                {(optionPack?.kilometers_stage && bookingPreview?.pricing_type !== "tariff_package") && (
                                    <>
                                        <p className={"fs12 medium"}>Kilométrage : {packageContent?.kilometers_stage?.kilometers_per_day}</p>
                                    </>
                                )}
                                <Divider className={"mb-3 mt-1"} />
                            </>
                        )}
                        <Button
                            onClick={() => handlePackSelect()}
                            variant={"default"}
                            className={cx("d-flex justify-content-center align-items-center mx-auto mt-3",{"bg-main c-white": selected}, {"bg-main-light c-main" : !selected}, classes.choose_this_offer_btn)}
                        >
                            {selected ?
                                <>
                                    <span className={"fs14"}>Offre sélectionnée</span><FontAwesomeIcon className={"ms-1 fs12"} icon={faCheck}/>
                                </>
                                :
                                <span className={"fs14"}>Choisir cette offre</span>
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OptionPackItem;
