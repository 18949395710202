import React from 'react';
import {Col, Row} from "react-bootstrap";
import "./OptionList.module.scss"
import OptionGrid from '../OptionGrid/OptionGrid';

const OptionList = ({options, selectedOptions, changeOption, selectedPackOption, packOptions, tariffFrequency, formData, tariffDisplayMode, showOptionImage}) => {
    
    const packOption = packOptions?.find(po => po.id === selectedPackOption);

    return (
        <>
            <Row>
                <Col>
                    <p className={"fs20 pt-3 medium"}>Sélectionnez vos options</p>
                </Col>
            </Row>
            <Row>
                <OptionGrid 
                    options={options} 
                    alreadyInPack={packOption?.other_options || []}
                    selectedOptions={selectedOptions}
                    changeOption={changeOption}
                    tariffDisplayMode={tariffDisplayMode}
                    tariffFrequency={tariffFrequency}
                    // showOptionImage={showOptionImage}
                />
            </Row>
        </>
    );
};

export default OptionList;