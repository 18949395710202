import React from "react";
import { SearchProvider } from "./SearchProvider";
import { Search } from "./pages/Search";
import { ResumeOptions } from "./pages/ResumeOptions";
import { CustomerInfo } from "./pages/CustomerInfo";
import { ErrorBoundary } from "@sentry/react";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";

const ErrorBoundaryLayout = () => {
    return (
        <ErrorBoundary fallback={<ErrorComponent />}>
            <SearchProvider />
        </ErrorBoundary>
    );
}

const options = {
    basename: '/recherche'
};

if (window.vehicle_type) {
    options.basename = `${window.vehicle_type}/recherche`;
}

if (window.agency_code) {
    options.basename = `/agence/${window.agency_code.toLowerCase()}/recherche`;
}

export const searchRoutes = [
    {
        path: "/",
        element: <ErrorBoundaryLayout />,
        children: [
            {
                path: "/",
                element: <Search />
            },
            {
                path: `/options`,
                element: <ResumeOptions />
            },
            {
                path: `/infos-client`,
                element: <CustomerInfo />
            }
        ]
    }
];

export const routerOptions = options;