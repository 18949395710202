import React, {useMemo} from 'react';
import FilterGroupItem from "./FilterGroupItem";
import {Col, Row} from "react-bootstrap";

const FilterGroupList = ({filterGroups, selectedFilterGroup, onSelect}) => {

    const colSize = useMemo(() => {
        const totalColumns = 12;
        const listSize = filterGroups.length + 1;
        const columnSizes = [];
        const baseSize = Math.floor(totalColumns / listSize);
        let remainingColumns = totalColumns;

        for (let i = 0; i < listSize - 1; i++) {
            const size = Math.min(baseSize, remainingColumns);
            columnSizes.push(size);
            remainingColumns -= size;
        }
        return columnSizes;
    }, [filterGroups]);
    const handleGroupSelect = (id) => {
        onSelect && onSelect(id)
    }
    return (
        <Row className={"justify-content-between"}>
            <Col xs={colSize[0]} className={"my-3"} style={{minHeight: 50}}>
                <FilterGroupItem selected={selectedFilterGroup?.length === 0} onClick={handleGroupSelect}  />
            </Col>
            { filterGroups?.map((item, index) => {
                return (
                    <Col xs={colSize[index+1]} key={`filter-group-${item.id}`}  className={"my-3"} style={{minHeight: 50}}>
                        <FilterGroupItem filterGroup={item} onClick={handleGroupSelect} selected={selectedFilterGroup.includes(item?.id)}/>
                    </Col>
                )
            })}
        </Row>
    );
};

export default FilterGroupList;
