import React from "react";
import { useSearch } from "../SearchProvider";
import { Col, Row } from "react-bootstrap";
import BookingDate from "../components/BookingDates";
import BookingRecap from "../components/BookingRecap";
import CustomerInformations from "./customerInformations";
import { useNavigate } from "react-router-dom";

export const CustomerInfo = () => {
    const {
        agencyList,
        selectedVehicle,
        bookingPreview,
        bookingPreviewLoading,
        couponData,
        selectedFranchiseLevels,
        kilometersStage,
        tariffFrequencyText,
        searchParameters,
        setStepHeader,
        handleCustomerUpdate,
        handleBookingSave,
        customer,
        loading,
        customFieldValues,
        handleBirthDateChange,
        cookiePriceHasChanged
    } = useSearch();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (cookiePriceHasChanged) {
            setStepHeader(0);
            navigate("/options");
        }
    }, [cookiePriceHasChanged]);

    return (
        <>
            <p className={"fs20 py-3 bold"}>Inscrivez vous pour confirmer votre réservation</p>
            <Row className={"pb-3"}>
                <Col xl={4} className={"d-xl-block d-none"}>
                    <BookingDate
                        editable={false}
                        readOnlyData={searchParameters}
                        agencies={agencyList}
                    // vehicle={vehicles[0]}
                    />
                    {selectedVehicle &&
                        <BookingRecap
                            vehicle={selectedVehicle}
                            bookingPreview={bookingPreview}
                            loading={bookingPreviewLoading}
                            couponData={couponData}
                            selectedFranchiseLevels={selectedFranchiseLevels}
                            kilometersStage={kilometersStage}
                            tariffFrequency={tariffFrequencyText}
                        />
                    }
                </Col>
                <Col xl={8} className={""}>
                    <CustomerInformations
                        setStepHeader={setStepHeader}
                        customerData={customer}
                        onChange={handleCustomerUpdate}
                        onSaveBooking={handleBookingSave}
                        customerLoading={loading}
                        bookingPreview={bookingPreview}
                        customFieldValues={customFieldValues}
                        onBirthDateChange={handleBirthDateChange}
                    />
                </Col>
            </Row>
        </>
    );
};