import React from "react";
import ResizeDetector from "react-resize-detector";
import SearchStepper from "./components/SearchStepper/SearchStepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Modal, ModalBody } from "react-bootstrap";
import { Collapse } from "@mui/material";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import FilterGroupList from "./components/FilterGroup/FilterGroupList";
import { useSearch } from "./SearchProvider";
import Header from "../components/Header/Header";
import { faChevronDown, faChevronUp, faFilter, faUser } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import ResponsiveSearchForm from "./components/ResponsiveSearchForm";
import { useCustomer } from "../../customHooks/useCustomer";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import BookingCouponAmount from "../components/BookingCouponAmount/BookingCouponAmount";
import '../components/CustomerMenu/CustomerMenu.module.scss';
import { useStyleContext } from "../../customHooks/useStyleContext";
import { useLocation } from "react-router-dom";
import MenuContainer from "../components/MenuContainer/MenuContainer";

export const SearchLayout = ({ children }) => {
    const styleContext = useStyleContext();
    const { isLog, setOpenModalLogin } = useCustomer();
    const routerLocation = useLocation();
    const {
        stepHeader,
        filterGroups,
        backgroundColor,
        agencyList,
        searchParameters,
        handleSearchDateAndPlaceUpdate,
        loading,
        toggleSearchModal,
        selectedVehicle,
        bookingPreview,
        bookingPreviewCollapse,
        setBookingPreviewCollapse,
        bookingPreviewLoading,
        selectedFranchiseLevels,
        kilometersStage,
        couponData,
        tariffFrequencyText,
        routeLocation,
        objectFromQuery,
        toggleFilterModal,
        filterModalOpen,
        handleFilterSelect,
        selectedFilterGroups,
        objectFromQueryStr
    } = useSearch();

    React.useEffect(() => {
        if (filterGroups?.length > 0 && objectFromQueryStr !== "{}") {
            const filterGroupId = Number(objectFromQuery?.filter_group_id);

            handleFilterSelect(filterGroupId);
        }
    }, [filterGroups, objectFromQueryStr]);

    return (
        <>
            {/* Subdivide into small component (aka: SearchNavigation, LogoContainer, etc.) */}
            <Header
                styles={styleContext}
                stepHeader={stepHeader}
                headerAlignment={"justify-content-between"}
                rightComponent={(
                    <>
                        <div className={cx("w-100 d-lg-flex d-none", { "justify-content-end": !styleContext?.header?.configs?.["config-hide_logo_on_app"], "justify-content-center": styleContext?.header?.configs?.["config-hide_logo_on_app"] })}>
                            <ResizeDetector
                                handleWidth
                                render={({ width }) => {
                                    return (
                                        <div className={"w-75"}>
                                            <SearchStepper step={stepHeader} hideLabel={false} canHideLabel={false} />
                                        </div>
                                    )
                                }}
                            />
                        </div>
                        <div className="d-flex d-lg-none ml-auto">
                            <MenuContainer hideLink={true} hideReturn={true} />
                        </div>
                        {filterGroups?.length > 0 && (
                            <div>
                                <div className={"p-4 position-relative d-block d-lg-none"} onClick={toggleFilterModal}>
                                    <FontAwesomeIcon icon={faFilter} className={"c-grey fs30"} />
                                    {selectedFilterGroups?.length > 0 && (<span className={"bold c-white bg-main justify-content-center align-items-center d-flex h20 w20 rounded-circle"}
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                    >{selectedFilterGroups.length}</span>)}
                                </div>
                                <Modal
                                    show={filterModalOpen}
                                    fullscreen={true}
                                    contentClassName={"w-100"}
                                    centered
                                    animation={true}
                                >
                                    <ModalHeader className={cx('d-flex justify-content-between')}>
                                        <p className={"medium fs18"}>Filtres {selectedFilterGroups?.length > 0 && (<span className={'fst-italic bold c-main'}> - {selectedFilterGroups?.length}</span>)}</p>
                                        <Button onClick={toggleFilterModal}>Fermer</Button>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FilterGroupList filterGroups={filterGroups} selectedFilterGroup={selectedFilterGroups} onSelect={handleFilterSelect} />
                                    </ModalBody>
                                </Modal>
                            </div>
                        )}
                    </>
                )}
            />
            <div className={cx("booking-module", { "bg-light-grey": !Boolean(backgroundColor) })} style={{
                paddingTop: '75px',
                height: "calc(var(--app-height))",
                overflowY: "scroll",
                overflowX: "hidden",
                backgroundColor: backgroundColor ?? "transparent"
            }}>
                <div className={cx("shadow-sm bg-white d-xl-none", { "sticky-top": routerLocation.pathname !== "/" })}>
                    <div className={"d-flex justify-content-around"}>
                        <div className={"p-2 w-100"}>
                            <div className={"d-flex justify-content-between"}>
                                <p className={"medium"}>{routerLocation.pathname === "/" ? 'Votre recherche' : 'Récapitulatif de votre réservation'}</p>
                            </div>
                            <ResponsiveSearchForm
                                editable={routerLocation.pathname === "/"}
                                agencies={agencyList}
                                params={objectFromQuery}
                                searchParameters={searchParameters}
                                onSearch={handleSearchDateAndPlaceUpdate}
                                loading={loading}
                                onInputClick={toggleSearchModal}
                            />
                            {selectedVehicle && (
                                <>
                                    <hr className={"my-2"} />
                                    <div className={"d-flex justify-content-between pointer"} onClick={() => setBookingPreviewCollapse(prevState => !prevState)}>
                                        <p>Montant Total :  <span className={"bold no-tr"}>{bookingPreviewLoading?.length ? "..." : `${parseFloat(bookingPreview?.total_amount).toFixed(2)} ${styleContext?.currency?.symbol ?? ''}`}</span></p>
                                        <p className={"fst-italic"}><span className={"ms-4"}><FontAwesomeIcon icon={bookingPreviewCollapse ? faChevronUp : faChevronDown} /></span></p>
                                    </div>
                                    <Collapse in={bookingPreviewCollapse} >
                                        <div className={"d-flex justify-content-between mt-3"}>
                                            <p className={"regular"}>Durée</p>
                                            <p className={"medium"}>{bookingPreview?.nb_days} jour{bookingPreview?.nb_days > 1 && 's'}</p>
                                        </div>
                                        {(selectedFranchiseLevels?.length > 0 || bookingPreview?.option_pack_amount?.franchise_level) && (
                                            <>
                                                {bookingPreview?.option_pack_amount?.franchise_level ? (
                                                    <>
                                                        <div className={"d-flex justify-content-between mt-1"}>
                                                            <p className={"regular"}>Montant de la franchise</p>
                                                            {loading?.length ? <>...</> : (
                                                                <p className={"bold"}>{`${bookingPreview?.option_pack_amount?.franchise_level?.franchise_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                            )}
                                                        </div>
                                                        <div className={"d-flex justify-content-between mt-1"}>
                                                            <p className={"regular"}>Montant de la caution</p>
                                                            {loading?.length ? <>...</> : (
                                                                <p className={"bold"}>{`${bookingPreview?.option_pack_amount?.franchise_level?.deposit_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>

                                                        {selectedFranchiseLevels?.map(franchiseLevel => {
                                                            return <div
                                                                key={`franchise-level-recap-mobile-${franchiseLevel?.id}`}>
                                                                <div className={"d-flex justify-content-between mt-1"}>
                                                                    <p className={"regular"}>
                                                                        {franchiseLevel?.franchise_level_type?.name ?
                                                                            <span>Franchise {franchiseLevel?.franchise_level_type?.name}</span>
                                                                            :
                                                                            <span>Montant de Franchise</span>
                                                                        }
                                                                    </p>
                                                                    {loading?.length ? <>...</> : (
                                                                        <p className={"bold"}>{`${franchiseLevel?.franchise_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        })}
                                                        {bookingPreview?.deposit && (
                                                            <div className={"d-flex justify-content-between mt-1"}>
                                                                <p className={"regular"}>Montant de la caution</p>
                                                                {loading?.length ? <>...</> : (
                                                                    <p className={"bold"}>{`${bookingPreview?.deposit?.amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {(kilometersStage || bookingPreview?.tariff_package?.kilometers || bookingPreview?.option_pack_amount?.kilometers_stage) &&
                                            <div className={"d-flex justify-content-between mt-1"}>
                                                <p className={"regular"}>Kilométrage</p>
                                                {loading?.length ? <>...</> : (
                                                    bookingPreview?.tariff_package ? (
                                                        <p className={"bold"}>{bookingPreview?.tariff_package?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.tariff_package?.kilometers}Km`} </p>
                                                    ) : (
                                                        bookingPreview?.option_pack_amount?.kilometers_stage ? (
                                                            <p className={"bold"}>{bookingPreview?.option_pack_amount?.kilometers_stage?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.option_pack_amount?.kilometers_stage?.kilometers_per_day}Km/${tariffFrequencyText}`} </p>
                                                        ) : (
                                                            <p className={"bold"}>{kilometersStage?.unlimited_kilometers ? "Illimité" : `${kilometersStage?.kilometers_per_day}Km/${tariffFrequencyText}`} </p>
                                                        )
                                                    )
                                                )}
                                            </div>
                                        }

                                        <div className={"d-flex justify-content-between mt-3"}>
                                            {/* FEATURE: manage ttc or not */}
                                            <p className={"medium"}>Montant total TTC <span className={"fs12 fst-italic"}>(hors option)</span></p>
                                            <p className={"bold"}>{bookingPreview?.amount ? parseFloat(bookingPreview.amount).toFixed(2) : ''} {styleContext?.currency?.symbol ?? ''}</p>
                                        </div>
                                        {bookingPreview?.option_amount_details?.cdw_option ? <>
                                            <div className={"d-flex justify-content-between mt-2"}>
                                                <p className={"medium"}>{bookingPreview?.option_amount_details?.cdw_option?.option_name}</p>
                                                {bookingPreviewLoading.length ? <>...</> : <p className={"bold"}>{`${bookingPreview?.option_amount_details?.cdw_option?.amount}${styleContext?.currency?.symbol ?? ''}`}</p>}
                                            </div>
                                        </> : <></>}

                                        {selectedFranchiseLevels?.length > 0 && bookingPreview?.franchise_level_amount != null ? <>
                                            {bookingPreview?.franchise_level_amount > 0 && (
                                                <div className={"d-flex justify-content-between mt-1"}>
                                                    <p className={"regular"}>Rachat de franchise</p>
                                                    {loading?.length ? <>...</> : <p className={"bold"}>{`${bookingPreview?.franchise_level_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>}
                                                </div>
                                            )}
                                        </> : <></>}

                                        {bookingPreview?.option_pack_amount?.name ? <>
                                            <div className={"d-flex justify-content-between mt-2"}>
                                                <p className={"regular"}>{bookingPreview?.option_pack_amount?.name}</p>
                                                {bookingPreviewLoading?.length ? <>...</> : <p className={"bold"}>{`${bookingPreview?.option_pack_amount?.amount}${styleContext?.currency?.symbol ?? ''}`}</p>}
                                            </div>
                                        </> : <></>}
                                        {(bookingPreview?.option_pack_amount?.options?.length > 0 || bookingPreview?.option_amount_details?.options?.length) > 0 && (
                                            <>
                                                <p className={"mt-2 medium"}>Options</p>
                                                <hr className={"my-2"} />
                                                <div className={""}>
                                                    {bookingPreview?.option_pack_amount?.options?.map((option) => {
                                                        return <div key={`option-pack-recap-${option.option_id}`} className={"d-flex justify-content-between"}>
                                                            <p className={"regular"}>{option.option_name}</p>
                                                            {bookingPreviewLoading.length ? <>...</> : <p className={"light fst-italic c-main"}>Inclus</p>}
                                                        </div>
                                                    })}
                                                    {bookingPreview?.option_amount_details?.options?.length ?
                                                        <>
                                                            {bookingPreview?.option_amount_details?.options?.map((option) => {
                                                                return <div key={`option-recap-${option.option_id}`} className={"d-flex justify-content-between"}>
                                                                    <p className={"regular"}>{option.option_name} {option.quantity > 1 && `(x${option.quantity})`}</p>
                                                                    {bookingPreviewLoading.length ? <>...</> : <p className={"bold"}>{`${option.amount}${styleContext?.currency?.symbol ?? ''}`}</p>}
                                                                </div>
                                                            })}
                                                        </> :
                                                        <></>
                                                    }
                                                    {(!bookingPreview?.option_pack_amount?.options.length && !bookingPreview?.option_amount_details?.options?.length) && <>
                                                        <p className={"fst-italic c-grey"}>Aucune option</p>
                                                    </>}

                                                </div>
                                            </>
                                        )}
                                        {couponData?.code && <>
                                            <div className={"d-flex justify-content-between mt-2 border bradius-5 p-2"}>
                                                <div className={"d-flex align-items-center"}>
                                                    <FontAwesomeIcon className={"me-2"} icon={faTag} />
                                                    <p><span
                                                        className={"medium"}>{couponData?.code ?? couponData?.name} {couponData.type === "percentage" && <>(-{parseInt(couponData?.value)} %)</>}</span>
                                                    </p>
                                                </div>
                                                {bookingPreviewLoading?.length ? <>...</> :
                                                    <BookingCouponAmount
                                                        className={"regular fst-italic"}
                                                        couponAmount={bookingPreview?.coupon_amount}
                                                        couponType={couponData?.type}
                                                        invoicingMode={bookingPreview?.invoicing_mode}
                                                        vatRate={bookingPreview?.vat_rate}
                                                        currencySymbol={styleContext?.currency?.symbol}
                                                    />
                                                }
                                            </div>
                                        </>}
                                        {styleContext?.app_config?.booking_conditions?.length > 0 && (
                                            <>
                                                <hr />
                                                <div className={"bg-white p-2 mt-3"}>
                                                    <p className={"medium fs14 underline mb-1"}>Conditions de
                                                        réservation :</p>
                                                    <div className={"bg-white p-2"}>
                                                        {styleContext?.app_config?.booking_conditions?.map((conditionItem, index) => {
                                                            return <p key={`booking-condition-text-${index}`} className={"fs14"}>- {conditionItem}</p>
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Collapse>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={cx({ "bg-light-grey": !Boolean(backgroundColor) })} >
                    <Container>
                        {children}
                    </Container>
                </div>
            </div>
        </>
    );
};