import React, { useState } from 'react';
import Stepper from "@mui/material/Stepper";
import cx from "classnames";
import { Step, StepLabel } from "@mui/material";
import StepperIcon from "./StepperIcon";

import "./SearchStepper.scss"
import classes from "./SearchStepper.scss.json"
import { useStyleContext } from '../../../../customHooks/useStyleContext';

const SearchStepper = ({ step, alternativeLabel, hideLabel = false, canHideLabel = true }) => {
    const [displayLabel, setDisplayLabel] = useState(hideLabel)

    const toggleLabelView = () => {
        canHideLabel && setDisplayLabel(prevState => !prevState)
    }

    const styleContext = useStyleContext()

    let labelStyle = {
        fontSize: styleContext?.header?.configs?.["config-app_header_font_size"] ?? "12px"
    }

    return (
        <>
            <Stepper className={cx("p-2 w-100", classes.stepper, { [classes['hidden-label']]: displayLabel })} activeStep={step} alternativeLabel={alternativeLabel} onClick={toggleLabelView}>
                <Step>
                    <StepLabel StepIconComponent={StepperIcon}>
                        <span className={cx("c-header-text", { "light fst-italic": step > 0 }, { "c-header-highlight-text bold": step === 0 }, { "w50 d-block": displayLabel })}><span className={cx({ "d-none w50": displayLabel })} style={labelStyle}>Choix du bien</span></span>
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel StepIconComponent={StepperIcon}>
                        <span className={cx("c-header-text", { "light fst-italic": step > 1 }, { "c-header-highlight-text bold": step === 1 }, { "w50 d-block": displayLabel })}><span className={cx({ "d-none w50": displayLabel })} style={labelStyle}>Choix des options</span></span>
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel StepIconComponent={StepperIcon}>
                        <span className={cx("c-header-text", { "light fst-italic": step > 2 }, { "c-header-highlight-text bold": step === 2 }, { "w50 d-block": displayLabel })}><span className={cx({ "d-none": displayLabel })} style={labelStyle}>Vos informations</span></span>
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel StepIconComponent={StepperIcon}>
                        <span className={cx("c-header-text", { "light fst-italic": step > 3 }, { "c-header-highlight-text bold": step === 3 }, { "w50 d-block": displayLabel })}><span className={cx({ "d-none": displayLabel })} style={labelStyle}>Confirmation de votre demande</span></span>
                    </StepLabel>
                </Step>
            </Stepper>
        </>
    );
};

export default SearchStepper;
