import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import { addDays, addHours, format, isValid, parseISO, startOfHour } from "date-fns";
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import "../../search-box/components/search-box.scss"
import searchBoxClasses from "../../search-box/components/search-box.scss.json"
import sandbox from "../../../service/Sandbox";
import { useStyleContext } from '../../../customHooks/useStyleContext';

const BookingDate = ({ agencies, params, onSearch, editable = true, className, onInputClick, searchParameters, loading, readOnlyData }) => {

    const pageAgency = window.agency
    const styleContext = useStyleContext();

    const [errorMessage, setErrorMessage] = useState(null);
    const [state, setState] = useState({
        start_agency: params?.start_agency ?? '',
        end_agency: params?.end_agency ?? '',
        start_date: params?.start_date ?? '',
        end_date: params?.end_date ?? '',
    });

    const showStartAgency = useMemo(() => {
        if (window.agency) { return false }
        return agencies?.length > 1 && !styleContext?.app_config?.disable_agency_choice
    }, [agencies])

    const showEndAgency = useMemo(() => {
        if (window.agency) { return false }
        return agencies?.length > 1 && !styleContext?.app_config.single_agency && !styleContext?.app_config?.disable_agency_choice
    }, [agencies])


    const formatReadOnlyData = useMemo(() => {
        const startDate = readOnlyData?.start_date
        const endDate = readOnlyData?.end_date
        let startAgencyName = sandbox.getAgencyName(agencies, readOnlyData?.start_agency)
        let endAgencyName = sandbox.getAgencyName(agencies, readOnlyData?.end_agency)
        let startDateObj = null
        let endDateObj = null

        if (isValid(startDate) && isValid(endDate)) {
            startDateObj = startDate
            endDateObj = endDate
        }
        if (isValid(parseISO(startDate)) && isValid(parseISO(endDate))) {
            startDateObj = parseISO(startDate)
            endDateObj = parseISO(endDate)
        }

        return {
            start_date: startDateObj,
            end_date: endDateObj,
            start_agency_name: startAgencyName,
            end_agency_name: endAgencyName
        }
    }, [readOnlyData]);


    const [datePickerState, setDatePickerState] = useState({
        // start_date: startOfHour(addHours(new Date(), 3)),
        // end_date: startOfHour(addHours(addDays(new Date(), 2),3))
        start_date: null,
        end_date: null,
    })

    const [agencyNames, setAgencyNames] = useState()

    useEffect(() => {
        if (agencies?.length === 1) {
            const agency = agencies?.[0]
            setAgencyNames({
                start_agency: sandbox.getAgencyName(agencies, agency?.id),
                end_agency: sandbox.getAgencyName(agencies, agency?.id)
            })
            setState(prevState => {
                return {
                    ...prevState,
                    start_agency: agency?.id ?? '',
                    end_agency: agency?.id ?? '',
                }
            })
        }
    }, [agencies])

    useEffect(() => {
        setAgencyNames({
            start_agency: sandbox.getAgencyName(agencies, state.start_agency),
            end_agency: sandbox.getAgencyName(agencies, state.end_agency)
        })
    }, [agencies, state.start_agency, state.end_agency])

    useEffect(() => {
        let newDateState = { ...datePickerState }
        const startDate = parseISO(state.start_date)
        if (isValid(startDate)) {
            newDateState.start_date = startDate
        }
        const endDate = parseISO(state.end_date)
        if (isValid(endDate)) {
            newDateState.end_date = endDate
        }
        setDatePickerState(newDateState)
    }, [])

    useEffect(() => {
        if (datePickerState?.start_date) {
            console.log(datePickerState?.start_date);
            setState(prevState => {
                return {
                    ...prevState,
                    start_day: format(datePickerState?.start_date, "yyyy-MM-dd"),
                    start_hour: format(datePickerState?.start_date, "HH:mm")
                }
            })
            console.log(datePickerState?.start_date)
        }
    }, [datePickerState?.start_date]);
    useEffect(() => {
        if (datePickerState?.end_date) {
            setState(prevState => {
                return {
                    ...prevState,
                    end_day: format(datePickerState?.end_date, "yyyy-MM-dd"),
                    end_hour: format(datePickerState?.end_date, "HH:mm")
                }
            })
            console.log(datePickerState?.end_date)
        }
    }, [datePickerState?.end_date]);

    useEffect(() => {
        if (searchParameters) {
            setState(prevState => {
                let newState = {
                    ...prevState,
                    start_agency: searchParameters?.start_agency ?? '',
                    end_agency: searchParameters?.end_agency ?? ''
                }
                if (isValid(searchParameters?.start_date)) {
                    newState.start_date = format(searchParameters?.start_date, "yyyy-MM-dd HH:mm")
                } else {
                    newState.start_date = searchParameters.start_date
                }
                if (isValid(searchParameters?.end_date)) {
                    newState.end_date = format(searchParameters?.end_date, "yyyy-MM-dd HH:mm")
                } else {
                    newState.end_date = searchParameters.end_date
                }
                if (agencies?.length === 1) {
                    newState.start_agency = agencies?.[0].id
                    newState.end_agency = agencies?.[0].id
                }
                return newState
            })
            setDatePickerState(prevState => {
                const newState = { ...prevState }
                if (isValid(searchParameters?.start_date)) {
                    newState.start_date = searchParameters?.start_date
                } else {
                    const parsed = parseISO(searchParameters?.start_date);
                    if (isValid(parsed)) {
                        newState.start_date = parsed
                    }
                }
                if (isValid(searchParameters?.end_date)) {
                    newState.end_date = searchParameters?.end_date
                } else {
                    const parsed = parseISO(searchParameters?.end_date);
                    if (isValid(parsed)) {
                        newState.end_date = parsed
                    }
                }
                return newState
            })
        }
    }, [searchParameters])

    // useEffect(() => {
    //     if(datePickerState?.start_date && datePickerState?.end_date && !loading ) {
    //         sendSearchRequest()
    //     }
    // }, [
    //     // datePickerState?.start_date,
    //     // datePickerState?.end_date,
    //     state.start_agency,
    //     state.end_agency
    // ]);

    const handleSearchButtonClick = (e) => {
        e.preventDefault();

        if (!datePickerState?.start_date || !datePickerState?.end_date) {
            setErrorMessage("Veuillez renseigner les dates de départ et de retour");
            return;
        }

        if (errorMessage) {
            setErrorMessage(null);
        }

        sendSearchRequest();
    }

    const sendSearchRequest = () => {
        const data = {
            start_date: format(datePickerState?.start_date, "yyyy-MM-dd HH:mm"),
            end_date: format(datePickerState?.end_date, "yyyy-MM-dd HH:mm"),
        }
        console.log({ state })
        if (state.start_agency) {
            data.start_agency = state.start_agency
        }
        if (state.end_agency) {
            data.end_agency = state.end_agency
        }

        console.log(data)
        onSearch && onSearch(data)
    }

    const handleInputClick = (name) => {
        /*
            start_agency: 'start_agency',
            start_day: 'start_day',
            start_hour: 'start_hour',
            end_agency: 'end_agency',
            end_day: 'end_day',
            end_hour: 'end_hour',
         */
        onInputClick && onInputClick(name)
    }

    return (
        <div className={cx(className, "bg-white bradius-5 p-3 d-flex flex-column")}>
            <p className={"medium fs18"}>{editable ? "Votre recherche" : "Récapitulatif de votre réservation"}</p>

            {pageAgency && (
                <Row className={"mt-3"}>
                    <Col md={12} className={"mb-2"}>
                        <div className={searchBoxClasses["input-wrapper"]}>
                            <p className={"fs14"}>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className={cx("c-grey fs16 me-3")} />
                                <span className={"medium fst-italic fs16"}>{pageAgency?.public_name !== '' ? pageAgency?.public_name : pageAgency.name}</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            )}
            {editable ? (
                <>
                    <Divider>
                        <p className={"light c-main my-2"}>DEPART</p>
                    </Divider>
                    <Row>
                        {(showStartAgency) && (
                            <Col md={12} className={"mb-2"}>
                                <div className={searchBoxClasses["input-wrapper"]}>
                                    <input
                                        value={agencyNames?.start_agency ?? ""}
                                        className={cx(searchBoxClasses['place-input'], "agency-select form-control bg-white no_tr")}
                                        name="start_agency"
                                        readOnly
                                        onClick={() => handleInputClick('start_agency')}
                                        placeholder={"Choisissez une agence"}
                                    />
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16")} />
                                </div>
                            </Col>
                        )}
                        <Col md={6}>
                            <div className={searchBoxClasses["input-wrapper"]}>
                                <input
                                    value={datePickerState?.start_date ? format(datePickerState?.start_date, "dd/MM/yyyy") : ''}
                                    className={cx(searchBoxClasses['date-input'], "form-control bg-white no_tr")}
                                    name="start_day"
                                    placeholder={"__ /__ /____"}
                                    readOnly
                                    required
                                    onClick={() => handleInputClick('start_day')}
                                />
                                <FontAwesomeIcon icon={faCalendarAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16")} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={searchBoxClasses["input-wrapper"]}>
                                <input
                                    value={datePickerState?.start_date ? format(datePickerState?.start_date, "HH:mm") : ''}
                                    className={cx(searchBoxClasses['time-input'], "form-control bg-white no_tr")}
                                    name="start_hour"
                                    placeholder={"__:__"}
                                    readOnly
                                    required
                                    onClick={() => handleInputClick('start_hour')}
                                />
                                <FontAwesomeIcon icon={faClock} className={cx(searchBoxClasses["input-icon"], "c-grey fs16")} />
                            </div>
                        </Col>
                    </Row>
                    <Divider>
                        <p className={"light c-main my-2"}>RETOUR</p>
                    </Divider>
                    <Row>
                        {(showEndAgency) && (
                            <Col md={12} className={"mb-2"}>
                                <div className={searchBoxClasses["input-wrapper"]}>
                                    <input
                                        value={agencyNames?.end_agency ?? ""}
                                        className={cx(searchBoxClasses['place-input'], "agency-select form-control bg-white no_tr")}
                                        name="end_agency"
                                        readOnly
                                        onClick={() => handleInputClick('end_agency')}
                                        placeholder={"Choisissez une agence"}
                                    />
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16")} />
                                </div>
                            </Col>
                        )}
                        <Col md={6}>
                            <div className={searchBoxClasses["input-wrapper"]}>
                                <input
                                    value={datePickerState?.end_date ? format(datePickerState?.end_date, "dd/MM/yyyy") : ''}
                                    className={cx(searchBoxClasses['date-input'], "form-control bg-white no_tr")}
                                    name="end_day"
                                    placeholder={"__ /__ /____"}
                                    readOnly
                                    onClick={() => handleInputClick('end_day')}
                                />
                                <FontAwesomeIcon icon={faCalendarAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16")} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={searchBoxClasses["input-wrapper"]}>
                                <input
                                    value={datePickerState?.end_date ? format(datePickerState?.end_date, "HH:mm") : ''}
                                    className={cx(searchBoxClasses['time-input'], "form-control bg-white no_tr")}
                                    name="end_hour"
                                    placeholder={"__:__"}
                                    readOnly
                                    onClick={() => handleInputClick('end_hour')}
                                />
                                <FontAwesomeIcon icon={faClock} className={cx(searchBoxClasses["input-icon"], "c-grey fs16")} />
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Divider>
                        <p className={"light c-main my-2"}>DEPART</p>
                    </Divider>
                    <Row>
                        {(showStartAgency) && (
                            <Col md={12} className={"mb-2"}>
                                <div className={searchBoxClasses["input-wrapper"]}>
                                    <p className={"fs14"}><FontAwesomeIcon icon={faMapMarkerAlt} className={cx("c-grey fs16 me-3")} /> Agence : <span className={"medium fst-italic fs16"}>{formatReadOnlyData?.start_agency_name ?? ""}</span></p>
                                </div>
                            </Col>
                        )}
                        <Col md={12}>
                            <div className={searchBoxClasses["input-wrapper"]}>
                                <p className={"medium fst-italic fs16"}>
                                    {readOnlyData?.start_date ?
                                        <>
                                            <FontAwesomeIcon icon={faCalendarAlt} className={cx("c-grey fs16 me-3")} /> Le {readOnlyData?.start_date ? format(formatReadOnlyData?.start_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}
                                        </>
                                        :
                                        <>
                                            <FontAwesomeIcon icon={faCalendarAlt} className={cx("c-grey fs16 me-3")} /> Le {datePickerState?.start_date ? format(datePickerState?.start_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}
                                        </>
                                    }
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Divider>
                        <p className={"light c-main my-2"}>RETOUR</p>
                    </Divider>
                    <Row>
                        {(showEndAgency) && (
                            <Col md={12} className={"mb-2"}>
                                <div className={searchBoxClasses["input-wrapper"]}>
                                    <p className={"fs14"}><FontAwesomeIcon icon={faMapMarkerAlt} className={cx("c-grey fs16 me-3")} /> Agence : <span className={"medium fst-italic fs16"}>{formatReadOnlyData?.end_agency_name ?? ""}</span></p>
                                </div>
                            </Col>
                        )}
                        <Col md={12}>
                            <div className={searchBoxClasses["input-wrapper"]}>
                                <p className={"medium fst-italic fs16"}>
                                    {readOnlyData?.end_date ?
                                        <>
                                            <FontAwesomeIcon icon={faCalendarAlt} className={cx("c-grey fs16 me-3")} /> Le {readOnlyData?.end_date ? format(formatReadOnlyData?.end_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}
                                        </>
                                        :
                                        <>
                                            <FontAwesomeIcon icon={faCalendarAlt} className={cx("c-grey fs16 me-3")} /> Le {datePickerState?.end_date ? format(datePickerState?.end_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}
                                        </>
                                    }
                                </p>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            {editable ?
                <>
                    {errorMessage && <p className={"text-danger bold fs12"}>{errorMessage}</p>}
                    <Button
                        className={"mt-2"}
                        variant={"primary"}
                        onClick={handleSearchButtonClick}
                    >Rechercher</Button>
                </>
                :
                <Link to={"/"} className={"mt-3"}>
                    <Button className={"w-100"} variant={"primary"}>
                        <span className={"c-white"}>Modifier</span>
                    </Button>
                </Link>
            }
        </div>
    );
};

export default BookingDate;
