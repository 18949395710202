import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';

const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return date.getHours() === 0 && date.getMinutes() === 0
        ? format(date, "dd/MM/yyyy")
        : format(date, "dd/MM/yyyy HH:mm");
};

const getClosingPeriod = (agency, date) => {
    if (!agency || !date || !agency.closing_periods) {
        return { isInClosingPeriod: false, period: null };
    }
    const period = agency.closing_periods.find(period => {
        return new Date(date) >= new Date(period.start_date) && new Date(date) <= new Date(period.end_date);
    });
    return period ? { isInClosingPeriod: true, period } : { isInClosingPeriod: false, period: null };
};

const formatAgencyInfo = (agency, period) => {
    const agencyName = agency?.public_name || agency?.name || '';
    const startDate = period?.start_date ? formatDate(period.start_date) : '';
    const endDate = period?.end_date ? formatDate(period.end_date) : '';
    return { agencyName, startDate, endDate };
};

const ClosingPeriodMessage = ({ agencyName, startDate, endDate, additionalInfo }) => (
    <div className="py-2 pb-4 bg-light bradius-5">
        <p className="text-center medium fst-italic fs14">
            L'agence {agencyName} est exceptionnellement fermée du {startDate} au {endDate}
            {additionalInfo && ` et ${additionalInfo}`}
        </p>
    </div>
);

const AgencyClosingPeriod = ({ startAgency, endAgency, parametersStartDate, parametersEndDate }) => {
    const startMatchClosingPeriod = useMemo(() => getClosingPeriod(startAgency, parametersStartDate), [startAgency, parametersStartDate]);
    const endMatchClosingPeriod = useMemo(() => getClosingPeriod(endAgency, parametersEndDate), [endAgency, parametersEndDate]);

    if (startMatchClosingPeriod.isInClosingPeriod && endMatchClosingPeriod.isInClosingPeriod) {
        const startInfo = formatAgencyInfo(startAgency, startMatchClosingPeriod.period);
        const endInfo = formatAgencyInfo(endAgency, endMatchClosingPeriod.period);

        if (startInfo.agencyName === endInfo.agencyName && startInfo.startDate === endInfo.startDate && startInfo.endDate === endInfo.endDate) {
            return <ClosingPeriodMessage agencyName={startInfo.agencyName} startDate={startInfo.startDate} endDate={startInfo.endDate} />;
        } else if (startInfo.agencyName === endInfo.agencyName) {
            return <ClosingPeriodMessage agencyName={startInfo.agencyName} startDate={startInfo.startDate} endDate={startInfo.endDate} additionalInfo={`du ${endInfo.startDate} au ${endInfo.endDate}`} />;
        } else {
            return <ClosingPeriodMessage agencyName={startInfo.agencyName} startDate={startInfo.startDate} endDate={startInfo.endDate} additionalInfo={`l'agence ${endInfo.agencyName} est exceptionnellement fermée du ${endInfo.startDate} au ${endInfo.endDate}`} />;
        }
    }

    if (startMatchClosingPeriod.isInClosingPeriod) {
        const startInfo = formatAgencyInfo(startAgency, startMatchClosingPeriod.period);
        return <ClosingPeriodMessage agencyName={startInfo.agencyName} startDate={startInfo.startDate} endDate={startInfo.endDate} />;
    }

    if (endMatchClosingPeriod.isInClosingPeriod) {
        const endInfo = formatAgencyInfo(endAgency, endMatchClosingPeriod.period);
        return <ClosingPeriodMessage agencyName={endInfo.agencyName} startDate={endInfo.startDate} endDate={endInfo.endDate} />;
    }

    return null;
};

export default AgencyClosingPeriod;