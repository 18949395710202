import React, {useEffect, useState} from 'react';
import cx from "classnames"
import classes from "./OptionList.module.scss.json"
import "./OptionList.module.scss"
import {Button, TextField} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const NumberPicker = ({option, changeOption, selectedOptions}) => {
    const [inputValue, setInputValue] = useState( 0);

    useEffect(() => {
        const value = selectedOptions?.find(selectedOption => selectedOption?.option_id === option?.id)?.quantity ?? 0
        setInputValue(value)
    }, [selectedOptions]);

    let selected = selectedOptions.find(o => o.option_id === option.id) ?? {option_id : option?.id, quantity: 0}

    const handlePlusButton = () => {
        changeOption(selected?.option_id, selected?.quantity + 1)
    }
    const handleMinusButton = () => {
        changeOption(selected?.option_id, selected?.quantity - 1)
    }


    return (
        <div className={cx(classes.number_picker)}>
            <TextField
                className={cx(classes.textfield, "bg-white no-pointer-events")}
                size={'small'}
                inputProps={{
                    className: "text-center",
                    readOnly: true,
                }}
                type={'number'}
                name={"value"}
                margin={"dense"}
                value={inputValue ?? 0}
                readOnly={true}
                fullWidth
            />
            <Button onClick={handlePlusButton} className={cx(classes.plus, "rounded-circle", {[classes.disabled]: selected?.quantity === option?.limit_per_vehicle})}>
                <FontAwesomeIcon icon={faPlus} size='xs' />
            </Button>
            <Button onClick={handleMinusButton} className={cx(classes.minus, "rounded-circle", {[classes.disabled]: selected?.quantity === 0})}>
                <FontAwesomeIcon icon={faMinus} size='xs' />
            </Button>
        </div>
    );
};

export default NumberPicker;