import React, {useEffect} from 'react';
import CustomerForm from "../components/CustomerForm/CustomerForm";
import {Link} from "react-router-dom";
import ArrowCircleLeftIcon from "../components/CustomerForm/images/arrowCircleLeftIcon";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";

const CustomerInformations = ({setStepHeader, customerLoading, customerData, onChange, onSaveBooking, bookingPreview, customFieldValues, onBirthDateChange}) => {
    useEffect(() => {
        setStepHeader(2)
        document.querySelector(".booking-module")?.scrollTo({top: -9999,left: 0})
    }, [])
    return (
        <>
            <div className={"mb-5 p-3 bg-white border mt-3 m-lg-0 mb-4 customer-form"}>
                <CustomerForm
                    bookingPreview={bookingPreview}
                    customerLoading={customerLoading}
                    customerData={customerData}
                    onChange={onChange}
                    onSaveBooking={onSaveBooking}
                    customFieldValues={customFieldValues}
                    onBirthDateChange={onBirthDateChange}
                />
            </div>
            <div className={"mt-4"}>
                <Link to={"/options"}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} color={"#6d7278"} className={"fs18 me-2"} />
                    {/*<ArrowCircleLeftIcon fill={"#6d7278"} /> */}
                    <span style={{color: "#6d7278"}}>Retour à l’étape précédente</span>
                </Link>
            </div>
        </>
    );
};

export default CustomerInformations;