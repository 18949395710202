import {useEffect, useCallback, useRef} from "react"

function useDebounceEffect(effect, deps, delay = 250, onFirstRender = false) {
    const callback = useCallback(effect, deps)
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current || onFirstRender) {
            const timeout = setTimeout(callback, delay)
            return () => clearTimeout(timeout)
        } else { didMount.current = true;}
    }, [callback, delay])
}

export default useDebounceEffect