import React, {Fragment, useMemo} from "react";
import './OptionGrid.module.scss';
import classes from './OptionGrid.module.scss.json';
import NumberPicker from "../OptionList/numberPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import AmountDisplay from "../../../components/AmountDisplay/AmountDisplay";
import { useStyleContext } from "../../../../customHooks/useStyleContext";
import { useSearch } from "../../SearchProvider";
import {Divider} from "@mui/material";

const OptionGrid = ({
    options = [],
    alreadyInPack = [],
    selectedOptions = [],
    changeOption = (_) => { },
    tariffDisplayMode,
    tariffFrequency
}) => {
    const { extra_config } = useStyleContext();

    const { showOptionGroups, showOptionImage } = useSearch();

    const groupedOptions = useMemo(() => {
        const groups = {
            "no_group": {
                id: "no_group",
                label: "Autres options",
                position: 9999,
                options: []
            }
        };
        options.forEach((option) => {
            if (showOptionGroups && option.option_group?.id) {
                if (!groups[option.option_group?.id]) {
                    groups[option.option_group?.id] = {
                        id: option.option_group?.id,
                        label: option.option_group?.public_name || option.option_group?.name,
                        position: option.option_group.position,
                        options: [],
                    };
                }
                groups[option.option_group?.id].options.push(option);
            } else {
                groups["no_group"].options.push(option);
            }
        });
        return Object.values(groups)?.sort((a,b) => a.position - b.position);
    }, [options]);

    const notSelected = (option) => {
        return (!selectedOptions?.find(so => so?.option_id === option.id)?.quantity) || (selectedOptions?.find(so => so?.option_id === option.id)?.quantity === 0)
    }

    return (
        <div>
            {groupedOptions?.map((group, i) => (
                <Fragment key={i}>
                    {showOptionGroups && groupedOptions?.length > 1 && (
                        <div className={"d-flex align-items-center mt-4 "}>
                            <p className={"fs16 medium flex-grow"}>{group.label}</p>
                            <Divider className={'flex-fill'} sx={{ml: 3}} />
                        </div>
                    )}

                    <div className={classes['grid-container']}>
                        {group.options.map((opt, i) => {
                            const extra = {
                                included: {
                                    pack: alreadyInPack.some(oo => oo.id === opt.id),
                                    booking: opt.mandatory_on_web && opt.mandatory_on_web === true
                                },
                            };
                            return (
                                <Fragment key={i}>
                                    <GridItem
                                        opt={opt}
                                        includedInBooking={extra.included.booking}
                                        includedInPack={extra.included.pack}
                                        notSelected={notSelected}
                                        changeOption={changeOption}
                                        selectedOptions={selectedOptions}
                                        tariffDisplayMode={tariffDisplayMode}
                                        showPicture={showOptionImage}
                                        tariffFrequency={tariffFrequency}
                                    />
                                </Fragment>
                            );
                        })}
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

const GridItem = ({
    opt = {},
    includedInBooking = false,
    includedInPack = false,
    selectedOptions = [],
    notSelected = (_) => { },
    changeOption = (_) => { },
    tariffDisplayMode = '',
    showPicture = true,
    tariffFrequency = ''
}) => (
    <div className={classes['grid-item']}>
        <div className={classes["item-header"]}>
            <div className={classes["item-header-title"]}>
                <span className={classes["item-title"]}>{opt.name}</span>
                <span className={classes["item-price"]}>
                    <AmountDisplay
                        tariffDisplayMode={tariffDisplayMode}
                        invoicingMode={opt?.pricing_group_invoicing_mode}
                        exclAmount={opt.excl_default_amount}
                        inclAmount={opt.incl_default_amount}
                        defaultAmount={opt?.default_amount}
                    />
                    <span className="fs10">{opt.type === "per_day" && ` / ${tariffFrequency}`}</span>
                </span>

            </div>
            {showPicture && (
                <div className={classes["item-header-picture"]}>
                    {opt.id_icon_url && (
                        <img src={opt.id_icon_url.substr(opt.id_icon_url.indexOf('//'))} alt={opt.name} />
                    )}
                </div>
            )}
        </div>
        <div className={classes["item-description"]}>
            <p>{opt.description}</p>
        </div>
        <div className={classes["item-footer"]}>
            {includedInBooking ?
                (<span className={classes["item-included"]}>Inclus dans la réservation</span>)
                : includedInPack ?
                    (<span className={classes["item-included"]}>Inclus dans le pack</span>)
                    : opt.limit_per_vehicle === 1 ?
                        (notSelected(opt) ? (
                            <button type="button" onClick={() => changeOption(opt.id, 1)}
                                className={"btn btn-primary"}>
                                <FontAwesomeIcon icon={faPlus} className={"me-1"} />
                                <span>Ajouter</span>
                            </button>
                        ) : (
                            <button type="reset" onClick={() => changeOption(opt.id, 0)}
                                className={"btn btn-primary"}>
                                <FontAwesomeIcon icon={faTimes} className={"me-1"} />
                                <span>Enlever</span>
                            </button>
                        ))
                        :
                        (<NumberPicker option={opt} selectedOptions={selectedOptions} changeOption={changeOption} />)
            }
        </div>
    </div>
);

export default OptionGrid;