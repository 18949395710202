import React, { Fragment, useCallback, useMemo } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import classes from "./OptionList.module.scss.json";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useStyleContext } from '../../../../customHooks/useStyleContext';
import AmountDisplay from "../../../components/AmountDisplay/AmountDisplay";
import { useSearch } from '../../SearchProvider';

const KilometersStagesList = ({ kilometersStages, selectedKilometersStage, onKilometersStageChange, tariffFrequency, tariffDisplayMode }) => {

    const styleContext = useStyleContext();

    const sortedKilometersStages = useMemo(() => {
        return kilometersStages?.sort((a, b) => {
            if (a.included_in_booking_price && !b.included_in_booking_price) { return -1 }
            if (!a.included_in_booking_price && b.included_in_booking_price) { return 1 }
            return a.price_per_day - b.price_per_day
        })
    }, [kilometersStages]);

    const handleKilometersStageSelect = (kilometersStageId) => {
        onKilometersStageChange && onKilometersStageChange(kilometersStageId)
    }

    return (
        <>
            <Row>
                <Col>
                    <p className={"fs20 py-3 medium"}>Sélectionnez le kilométrage</p>
                </Col>
            </Row>
            <div className={classes['km-stage-container']}>
                {sortedKilometersStages?.map(kilometersStage => (
                    <Fragment key={kilometersStage?.id}>
                        <KilometersStagesItem
                            kilometersStage={kilometersStage}
                            selectedKilometersStage={selectedKilometersStage}
                            onKilometersStageChange={handleKilometersStageSelect}
                            tariffFrequency={tariffFrequency}
                            tariffDisplayMode={tariffDisplayMode}
                        />
                    </Fragment>
                ))}
            </div>
        </>
    );
};

const KilometersStagesItem = ({ kilometersStage, selectedKilometersStage, onKilometersStageChange, tariffFrequency, tariffDisplayMode }) => {
    const { bookingPreview } = useSearch();

    const isSelected = selectedKilometersStage === kilometersStage.id;

    const handleKilometersStageSelect = useCallback(() => {
        onKilometersStageChange && onKilometersStageChange(kilometersStage.id);
    }, [kilometersStage, onKilometersStageChange]);

    if (!bookingPreview) { return null; }

    return (
        <div className={classes['km-stage-item'] + " " + (isSelected ? 'border-main' : 'border')}>
            <div className={'d-flex flex-column ' + classes['km-stage-item-title-container']}>
                {kilometersStage?.unlimited_kilometers  ? (
                    <span className={classes['km-stage-item-title']}>
                        Vous {isSelected ? 'disposez' : 'disposerez'} de kilométrage illimité pour votre location.
                    </span>
                ) : (
                    <span className={classes['km-stage-item-title']}>
                        Vous {isSelected ? 'disposez' : 'disposerez'} de {kilometersStage.kilometers_per_day * (kilometersStage?.kilometer_type == "total" ? 1 : bookingPreview.nb_days)} kms pour votre location.
                        <>
                            {kilometersStage?.kilometer_type != "total" &&
                                <span>({kilometersStage.kilometers_per_day} kms)</span>
                            }
                        </>
                    </span>
                )}
                <div className={classes['km-stage-item-subtitle']}>
                    
                    {kilometersStage?.kilometer_type != "total" &&
                        <span>Par {tariffFrequency} : <AmountDisplay
                            tariffDisplayMode={tariffDisplayMode}
                            invoicingMode={kilometersStage?.pricing_group_invoicing_mode}
                            exclAmount={kilometersStage?.excl_price_per_day ?? 0}
                            inclAmount={kilometersStage?.incl_price_per_day ?? 0}
                            defaultAmount={kilometersStage?.price_per_day ?? 0}
                        />
                        </span>
                    }
                    
                    <span>Au total : <AmountDisplay
                        tariffDisplayMode={tariffDisplayMode}
                        invoicingMode={kilometersStage?.pricing_group_invoicing_mode}
                        exclAmount={(kilometersStage?.excl_price_per_day ?? 0) * (kilometersStage?.kilometer_type == "total" ? 1 : bookingPreview.nb_days)}
                        inclAmount={(kilometersStage?.incl_price_per_day ?? 0) * (kilometersStage?.kilometer_type == "total" ? 1 : bookingPreview.nb_days)}
                        defaultAmount={(kilometersStage?.price_per_day ?? 0) * (kilometersStage?.kilometer_type == "total" ? 1 : bookingPreview.nb_days)}
                    />
                    </span>
                </div>
            </div>
            {isSelected ? (
                <button className='btn' disabled={true} type='reset'>
                    <FontAwesomeIcon icon={faCheck} className='me-1' /> Sélectionné
                </button>
            ) : (
                <button className='btn' type='button' onClick={handleKilometersStageSelect}>
                    <FontAwesomeIcon icon={faPlus} className='me-1' /> Choisir
                </button>
            )}
        </div>
    );
};

export default KilometersStagesList;
