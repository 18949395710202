import React, {useCallback, useContext, useEffect} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import classes from "./OptionList.module.scss.json";
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus} from "@fortawesome/free-solid-svg-icons";
import { useStyleContext } from '../../../../customHooks/useStyleContext';
import AmountDisplay from "../../../components/AmountDisplay/AmountDisplay";

const FranchiseLevelList = ({franchiseLevels, selectedFranchiseLevelIds = [],onFranchiseLevelChange, tariffFrequency, tariffDisplayMode}) => {

    const styleContext = useStyleContext()

    const handleFranchiseLevelSelect = (franchiseLevelId) => {
        onFranchiseLevelChange && onFranchiseLevelChange(franchiseLevelId)
    }

    const handleFranchiseLevelUnselect = () => {
        onFranchiseLevelChange && onFranchiseLevelChange(null)
    }

    return (
        <>
            <Row>
                <Col>
                    <p className={"fs20 py-3 medium"}>Sélectionnez le montant de la franchise</p>
                </Col>
            </Row>
            <Row className={classes.options_list}>
                {franchiseLevels?.map(franchiseLevel => {
                    let editable = true
                    const isSelected = selectedFranchiseLevelIds?.includes(franchiseLevel.id) //selectedFranchiseLevel === franchiseLevel.id
                    return <Col key={franchiseLevel?.id} xs={12} >
                        <div className={cx("position-relative",{'border-main z-index-1' : isSelected || !editable }, {"border": !isSelected && editable }, "bg-white p-3")}>
                            <Row className={cx()}>
                                <Col xs={8}>
                                    <div>
                                        {
                                            franchiseLevel?.franchise_level_type ? (

                                                <span className={"medium"}>Franchise {franchiseLevel?.franchise_level_type?.name} : {franchiseLevel?.franchise_amount ?? ""}{styleContext?.currency?.symbol ?? ''}</span>
                                            ) : (
                                                <span className={"medium"}>Franchise de {franchiseLevel?.franchise_amount ?? ""}{styleContext?.currency?.symbol ?? ''}</span>
                                            )
                                        }
                                    </div>
                                    <div>
                                        {franchiseLevel?.price === 0 ? (
                                            <span className={"text-secondary fs14 medium"}>Prix : Gratuit</span>
                                        ):(
                                            <span className={"medium fs14"}>Prix : <span>
                                                <AmountDisplay
                                                    tariffDisplayMode={tariffDisplayMode}
                                                    invoicingMode={franchiseLevel?.pricing_group_invoicing_mode}
                                                    exclAmount={franchiseLevel.excl_price}
                                                    inclAmount={franchiseLevel.incl_price}
                                                    defaultAmount={franchiseLevel.price}
                                                /></span>
                                                {franchiseLevel?.price_type === "per_day" && `/${tariffFrequency}`}
                                            </span>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={4} className={"d-flex justify-content-center align-items-center"}>
                                    {editable ?
                                        <>
                                            { isSelected ?
                                                <Button
                                                    onClick={() => {}}
                                                    className={cx("bg-main c-white p-2 medium d-flex align-items-center justify-content-center", classes.add_option_btn)}
                                                >
                                                    <FontAwesomeIcon icon={faCheck} className={"me-1"} />
                                                    <span>Sélectionné</span>
                                                </Button>
                                            :
                                                <Button
                                                    onClick={() => handleFranchiseLevelSelect(franchiseLevel?.id)}
                                                    className={cx("bg-main-light c-main no-border p-2 medium d-flex align-items-center justify-content-center", classes.add_option_btn)}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className={"me-1"} />
                                                    <span>Choisir</span>
                                                </Button>
                                            }
                                        </>
                                    :
                                        <p className={"c-main medium"}>
                                            <FontAwesomeIcon icon={faCheck} color={styleContext?.colors?.['c-main']} /> Inclus dans le pack
                                        </p>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>

                })}
            </Row>
       </>
    );
};

export default FranchiseLevelList;
