import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react'

const getClosingPeriod = (agency, date, type) => {
    
    if (!agency || !date || !agency.operating_hours?.length > 0 ) {
        return { isInOperatingHour: false, period: null, showMessage: false };
    }
    const period = agency.operating_hours.find(period => {
        // check if the day and time is within the operating hours
        const periodMinHour = parseISO( format(new Date(date), `yyyy-MM-dd ${period.min_hour}:ss`));
        const periodMaxHour = parseISO( format(new Date(date), `yyyy-MM-dd ${period.max_hour}:ss`));
        return new Date(date) >= periodMinHour && new Date(date) <= periodMaxHour && new Date(date).getDay() === period.day && type === period.type;
    });

    let firstPeriodSameDay = agency.operating_hours.filter(p => {
        const periodMinHour = parseISO( format(new Date(date), `yyyy-MM-dd ${p.min_hour}:ss`));
        return p.day === new Date(date).getDay() && p.type === type && new Date(date) < periodMinHour;
    });
    if (firstPeriodSameDay.length > 1){
        firstPeriodSameDay = firstPeriodSameDay.sort((a, b) => a.min_hour - b.min_hour);
    }
    firstPeriodSameDay = firstPeriodSameDay[0];
    return period ? { isInOperatingHour: true, period, showMessage: false } : { isInOperatingHour: false, period: null, showMessage: true, firstPeriodSameDay };
};

const formatAgencyInfo = (agency, period) => {
    const agencyName = agency?.public_name || agency?.name || '';
    return { 
        agencyName, 
    };
};

const OperatingHourMessage = ({ agencyName, startDate, endDate, additionalInfo, type, startHour }) => (
    <div className="py-2 pb-4 bg-light bradius-5">
        <p className="text-center medium fst-italic fs14">
            Le {type} à l'agence {agencyName} se fait a partir de {startHour}.  
        </p>
    </div>
);




const AgencyOperatingHour = ({ startAgency, endAgency, parametersStartDate, parametersEndDate }) => {
    const startMatchClosingPeriod = useMemo(() => getClosingPeriod(startAgency, parametersStartDate, "booking_start"), [startAgency, parametersStartDate]);

    if (startMatchClosingPeriod.showMessage) {
        
        const startInfo = formatAgencyInfo(startAgency, startMatchClosingPeriod.period);
        return <OperatingHourMessage 
            agencyName={startInfo.agencyName} 
            startHour={startMatchClosingPeriod?.firstPeriodSameDay?.min_hour || null}
            type={"départ"}
        />;
    }
    
    return (
        <div>
            
        </div>
    )
}

export default AgencyOperatingHour
