import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { Divider, TextField } from "@mui/material";
import searchBoxClasses from "../../search-box/components/search-box.scss.json";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import sandbox from "../../../service/Sandbox";
import { format, isValid, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { useStyleContext } from '../../../customHooks/useStyleContext';

const ResponsiveSearchForm = ({ agencies, params, onInputClick, onSearch, searchParameters, loading, editable = true }) => {

    const pageAgency = window.agency

    const styleContext = useStyleContext()

    const [state, setState] = useState({
        start_agency: params?.start_agency ?? '',
        end_agency: params?.end_agency ?? '',
        start_date: params?.start_date ?? '',
        end_date: params?.end_date ?? '',
    });
    const [agencyNames, setAgencyNames] = useState()
    const [datePickerState, setDatePickerState] = useState({
        start_date: null,
        end_date: null,
    })

    const searchWarningMessage = useMemo(() => {
        return styleContext?.configs?.["content_config-search_warning_message"] ?? null
    }, [styleContext])

    const showStartAgencyField = useMemo(() => {
        if (window.agency) { return false }
        return agencies?.length > 1 && !styleContext?.app_config?.disable_agency_choice
    }, [agencies]);

    const showEndAgencyField = useMemo(() => {
        if (window.agency) { return false }
        return agencies?.length > 1 && !styleContext?.app_config.single_agency && !styleContext?.app_config?.disable_agency_choice
    }, [agencies]);

    const formatReadOnlyData = useMemo(() => {
        const startDate = searchParameters?.start_date
        const endDate = searchParameters?.end_date
        let startAgencyName = sandbox.getAgencyName(agencies, searchParameters?.start_agency)
        let endAgencyName = sandbox.getAgencyName(agencies, searchParameters?.end_agency)
        let startDateObj = null
        let endDateObj = null

        if (isValid(startDate) && isValid(endDate)) {
            startDateObj = startDate
            endDateObj = endDate
        }
        if (isValid(parseISO(startDate)) && isValid(parseISO(endDate))) {
            startDateObj = parseISO(startDate)
            endDateObj = parseISO(endDate)
        }

        return {
            start_date: startDateObj,
            end_date: endDateObj,
            start_agency_name: startAgencyName,
            end_agency_name: endAgencyName
        }
    }, [searchParameters, agencies]);


    useEffect(() => {
        let newDateState = { ...datePickerState }
        const startDate = parseISO(state.start_date)
        if (isValid(startDate)) {
            newDateState.start_date = startDate
        }
        const endDate = parseISO(state.end_date)
        if (isValid(endDate)) {
            newDateState.end_date = endDate
        }
        setDatePickerState(newDateState)
    }, [])

    useEffect(() => {
        setAgencyNames({
            start_agency: sandbox.getAgencyName(agencies, state.start_agency),
            end_agency: sandbox.getAgencyName(agencies, state.end_agency)
        })
    }, [agencies, state.start_agency, state.end_agency])

    useEffect(() => {
        if (datePickerState?.start_date) {
            console.log(datePickerState?.start_date);
            setState(prevState => {
                return {
                    ...prevState,
                    start_day: format(datePickerState?.start_date, "yyyy-MM-dd"),
                    start_hour: format(datePickerState?.start_date, "HH:mm")
                }
            })
            console.log(datePickerState?.start_date)
        }
    }, [datePickerState?.start_date]);

    useEffect(() => {
        if (datePickerState?.end_date) {
            setState(prevState => {
                return {
                    ...prevState,
                    end_day: format(datePickerState?.end_date, "yyyy-MM-dd"),
                    end_hour: format(datePickerState?.end_date, "HH:mm")
                }
            })
            console.log(datePickerState?.end_date)
        }
    }, [datePickerState?.end_date]);

    useEffect(() => {
        if (searchParameters) {
            setState(prevState => {
                let newState = {
                    ...prevState,
                    start_agency: searchParameters?.start_agency ?? '',
                    end_agency: searchParameters?.end_agency ?? ''
                }
                if (isValid(searchParameters?.start_date)) {
                    newState.start_date = format(searchParameters?.start_date, "yyyy-MM-dd HH:mm")
                } else {
                    newState.start_date = searchParameters.start_date
                }
                if (isValid(searchParameters?.end_date)) {
                    newState.end_date = format(searchParameters?.end_date, "yyyy-MM-dd HH:mm")
                } else {
                    newState.end_date = searchParameters.end_date
                }
                if (agencies?.length === 1) {
                    newState.start_agency = agencies?.[0].id
                    newState.end_agency = agencies?.[0].id
                }
                return newState
            })
            setDatePickerState(prevState => {
                const newState = { ...prevState }
                if (isValid(searchParameters?.start_date)) {
                    newState.start_date = searchParameters?.start_date
                } else {
                    const parsed = parseISO(searchParameters?.start_date);
                    if (isValid(parsed)) {
                        newState.start_date = parsed
                    }
                }
                if (isValid(searchParameters?.end_date)) {
                    newState.end_date = searchParameters?.end_date
                } else {
                    const parsed = parseISO(searchParameters?.end_date);
                    if (isValid(parsed)) {
                        newState.end_date = parsed
                    }
                }
                return newState
            })
        }
    }, [searchParameters])

    // useEffect(() => {
    //     if(datePickerState?.start_date && datePickerState?.end_date && !loading ) {
    //         sendSearchRequest()
    //     }
    // }, [
    //     state.start_agency,
    //     state.end_agency
    // ]);

    const handleSearchButtonClick = () => {
        sendSearchRequest()
    }

    const sendSearchRequest = () => {
        const data = {
            start_date: '',
            end_date: '',
        };

        if (datePickerState?.start_date) {
            data.start_date = format(datePickerState?.start_date, "yyyy-MM-dd HH:mm")
        }

        if (datePickerState?.end_date) {
            data.end_date = format(datePickerState?.end_date, "yyyy-MM-dd HH:mm")
        }
        
        if (state.start_agency) {
            data.start_agency = state.start_agency
        }
        if (state.end_agency) {
            data.end_agency = state.end_agency
        }

        console.log(data)
        onSearch && onSearch(data)
    }

    const handleInputClick = (name) => {
        onInputClick && onInputClick(name)
    }
    return (
        <div>
            {pageAgency && (
                <Row className={"align-items-center my-2"}>
                    <Col xs={12} className={""}>
                        <div className={searchBoxClasses["input-wrapper"]}>
                            <p className={"fs14"}>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className={cx("c-grey fs16 me-3")} />
                                <span className={"medium fst-italic fs16"}>{pageAgency?.public_name !== '' ? pageAgency?.public_name : pageAgency.name}</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            )}
            {editable ? (
                <>
                    <Row className={"align-items-center"}>
                        <Col xs={12} md={10} className={""}>
                            <Row>
                                {(showStartAgencyField) && (
                                    <Col xs={12} md={showEndAgencyField ? 6 : 12} lg={showEndAgencyField ? 6 : 4}
                                        className={"my-1"}>
                                        <TextField
                                            className={cx(searchBoxClasses["outline-input"], 'w-100')}
                                            variant={"outlined"}
                                            label={"Agence de départ"}
                                            margin={"dense"}
                                            size={"small"}
                                            placeholder={"Choisissez une agence"}
                                            InputProps={{
                                                startAdornment: <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16 me-2")} />,
                                                readOnly: true
                                            }}
                                            value={agencyNames?.start_agency ?? ""}
                                            onClick={() => handleInputClick('start_agency')}
                                        />
                                    </Col>
                                )}
                                <Col xs={12} md={6} lg={(showEndAgencyField || !showStartAgencyField) ? 6 : 4} className={"my-1"}>
                                    <TextField
                                        className={cx(searchBoxClasses["outline-input"], 'w-100')}
                                        variant={"outlined"}
                                        label={"Date de départ"}
                                        margin={"dense"}
                                        size={"small"}
                                        InputProps={{
                                            startAdornment: <FontAwesomeIcon icon={faCalendarAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16 me-2")} />,
                                            readOnly: true
                                        }}
                                        value={datePickerState?.start_date ? format(datePickerState?.start_date, "dd/MM/yyyy HH:mm") : ''}
                                        onClick={() => handleInputClick('start_day')}
                                    />
                                    {(showEndAgencyField) && (
                                        <div className={"d-md-none"}>
                                            <Divider className={"my-2 mt-3"} />
                                        </div>
                                    )}
                                </Col>
                                {(showEndAgencyField) && (
                                    <Col xs={12} md={6} lg={6} className={"my-1"}>
                                        <TextField
                                            className={cx(searchBoxClasses["outline-input"], 'w-100')}
                                            variant={"outlined"}
                                            label={"Agence de retour"}
                                            placeholder={"Choisissez une agence"}
                                            margin={"dense"}
                                            size={"small"}
                                            InputProps={{
                                                startAdornment: <FontAwesomeIcon icon={faMapMarkerAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16 me-2")} />,
                                                readOnly: true
                                            }}
                                            value={agencyNames?.end_agency ?? ""}
                                            onClick={() => handleInputClick('end_agency')}
                                        />
                                    </Col>
                                )}
                                <Col xs={12} md={6} lg={(showEndAgencyField || !showStartAgencyField) ? 6 : 4} className={"my-1"}>
                                    <TextField
                                        className={cx(searchBoxClasses["outline-input"], 'w-100')}
                                        variant={"outlined"}
                                        label={"Date de retour"}
                                        margin={"dense"}
                                        size={"small"}
                                        InputProps={{
                                            startAdornment: <FontAwesomeIcon icon={faCalendarAlt} className={cx(searchBoxClasses["input-icon"], "c-grey fs16 me-2")} />,
                                            readOnly: true
                                        }}
                                        value={datePickerState?.end_date ? format(datePickerState?.end_date, "dd/MM/yyyy HH:mm") : ''}
                                        onClick={() => handleInputClick('end_day')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}>
                            <Button
                                className={"my-1 w-100"}
                                variant={"primary"}
                                onClick={handleSearchButtonClick}
                            >Rechercher</Button>
                        </Col>
                    </Row>
                    {searchWarningMessage && <div className={"bg-white"}><p className={"fs12 medium mt-4 fst-italic px-3 py-2"} style={{ color: "rgb(254,159,48)", backgroundColor: "rgb(255,226,166, 0.4)" }}>{searchWarningMessage}</p></div>}
                </>
            ) : (
                <Row>
                    <Col md={9}>

                        <Row>
                            {showStartAgencyField && (
                                <Col md={6}>
                                    <p className={"fs14"}><FontAwesomeIcon icon={faMapMarkerAlt} className={cx("c-grey fs16 me-3")} /> Agence de départ : <span className={"medium fst-italic fs16"}>{formatReadOnlyData?.start_agency_name ?? ""}</span></p>
                                </Col>
                            )}
                            {showEndAgencyField && (
                                <Col md={6}>
                                    <p className={"fs14"}><FontAwesomeIcon icon={faMapMarkerAlt} className={cx("c-grey fs16 me-3")} /> Agence de retour : <span className={"medium fst-italic fs16"}>{formatReadOnlyData?.end_agency_name ?? ""}</span></p>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <p>
                                    <FontAwesomeIcon icon={faCalendarAlt} className={cx("c-grey fs16 me-3")} />
                                    {(searchParameters?.start_date && formatReadOnlyData?.start_date) ?
                                        <span>Du {searchParameters?.start_date ? format(formatReadOnlyData?.start_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}</span>
                                        :
                                        <span>Du {datePickerState?.start_date ? format(datePickerState?.start_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}</span>
                                    }
                                </p>
                            </Col>

                            <Col md={6}>
                                <p>
                                    <FontAwesomeIcon icon={faCalendarAlt} className={cx("c-grey fs16 me-3")} />
                                    {(searchParameters?.end_date && formatReadOnlyData?.end_date) ?
                                        <span>Au {searchParameters?.end_date ? format(formatReadOnlyData?.end_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}</span>
                                        :
                                        <span>Le {datePickerState?.end_date ? format(datePickerState?.end_date, "dd/MM/yyyy 'à' HH'h'mm") : '--/--/---- --:--'}</span>
                                    }
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Link to={"/"} className={"mt-3"}>
                            <Button className={"w-100"} variant={"primary"}>
                                <span className={"c-white"}>Modifier</span>
                            </Button>
                        </Link>
                    </Col>
                </Row>
            )}

        </div>
    );
};

export default ResponsiveSearchForm;
