import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../../../utils/countries";


const MuiCountrySelect = ({size, className, label='', onChange, required = false, defaultValue, disabled= false, inputProps= {}}) => {
    const france = countries?.find(item => item.code === 'fr')
    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        if(defaultValue) {
            const country = countries?.find(item => item.code === defaultValue?.toLowerCase())
            console.log(country)
            if(country) { setValue(country)}
        }
    }, [defaultValue]);


    const handleValueChange = (newValue) => {
        setValue(newValue);
        onChange && onChange(newValue)
    }

    return (
        <Autocomplete
            id="country-select-demo"
            sx={{ width: 300 }}
            className={className}
            options={countries}
            autoHighlight
            disabled={disabled}
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={(event, newValue) => {
                handleValueChange(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    <span className={"fs14"}>{option.label} </span>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={size}
                    label={label}
                    required={required}
                    inputProps={{
                        ...params.inputProps,
                        ...inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};

export default MuiCountrySelect;
