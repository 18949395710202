import React from 'react'
import classes from "./VehicleTypeTab.module.scss.json"
import "./VehicleTypeTab.module.scss"

import MaterialTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VehicleTypeDefaultIconByName from '../../components/VehicleTypeDefaultIconByName/VehicleTypeDefaultIconByName';

const VehicleTypeTab = ({ materialTabIndex, changeTab, actualVehicleTypes, showGlobal = false }) => {
    const tabProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    return (
        <>
            <div className={classes.tabs}>
                <MaterialTabs
                    value={materialTabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="fullWidth"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {showGlobal === true &&
                        <Tab onClick={() => changeTab(null, 0)} className={classes.list_tab_title} label={<div style={{ color: "#262626" }} className={"d-flex align-item-center"}>
                            <span className={"d-flex mr-2"}>
                                {/*<CarIcon />*/}
                            </span> Global
                        </div>} {...tabProps(0)}
                        />
                    }
                    {actualVehicleTypes?.map((vehicleType, i) => {
                        return (
                            <Tab key={vehicleType?.name + i} onClick={() => changeTab(vehicleType?.name, showGlobal ? i + 1 : i)} className={classes.list_tab_title} label={
                                <div style={{ color: vehicleType?.color }} className={"d-flex align-items-center"}>
                                    <span style={{ marginRight: "5px" }} className={"d-flex mr-2"}>
                                        <VehicleTypeDefaultIconByName name={vehicleType?.default_icon_name} />
                                    </span>
                                    <span>
                                        {vehicleType?.name}
                                    </span>
                                </div>} {...tabProps(showGlobal ? i + 1 : i)}
                            />
                        )
                    })}
                </MaterialTabs>
            </div>

        </>
    )
}

export default VehicleTypeTab