import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { searchRoutes, routerOptions } from "./searchRoutes";
import { LoaderContainer } from "../components/Loader/LoaderContainer";

export const Routing = () => {
    const [initialized, setInitialized] = React.useState(false);

    const routes = React.useMemo(() => searchRoutes, []);

    React.useEffect(() => {
        if (routes === null) {
            window.location.href = '/';
            return;
        }

        setInitialized(true);

        return () => {
            setInitialized(false);
        };
    }, [routes]);

    const router = React.useMemo(() => {
        return routes ? createBrowserRouter(routes, {
            basename: routerOptions?.basename ?? '/recherche'
        }) : null;
    }, [routes]);

    if (!initialized) {
        return <LoaderContainer color="var(--c-main)" />;
    }

    return (
        <React.Suspense fallback={<LoaderContainer color={"var(--c-main)"} />}>
            {router && <RouterProvider router={router} />}
        </React.Suspense>
    );
};