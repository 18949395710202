import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import "./OptionList.module.scss"
import OptionPackItem from "./OptionPackItem";

const OptionPacks = ({packOptions, changeOptionPack, selectedPackOption, category, formDataLoading, bookingPreview, tariffFrequency, tariffDisplayMode}) => {
    useEffect(() => {
        if (packOptions?.length > 0 && selectedPackOption == null){
            const autoSelectedPackOption = packOptions.find(po => po.selected === true)
            if (autoSelectedPackOption?.id){
                changeOptionPack(autoSelectedPackOption?.id)
            }
        }
    }, [packOptions])

    return (
        <div>
            <p className={"fs20 py-3 medium "}>Nos options en package</p>
            <Row>
                {packOptions?.map(pack => {
                    return <Col key={`option_pack_${pack.id}`} lg={4} className={""}>
                        <OptionPackItem
                            optionPack={pack}
                            onClick={changeOptionPack}
                            selectedPackId={selectedPackOption}
                            category={category}
                            bookingPreview={bookingPreview}
                            tariffFrequency={tariffFrequency}
                            tariffDisplayMode={tariffDisplayMode}
                        />
                    </Col>
                })}

            </Row>
        </div>
    );
};

export default OptionPacks;