import React, {useContext} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import cx from "classnames";
import classes from "./OptionList.module.scss.json";
import NumberPicker from "./numberPicker";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useStyleContext } from '../../../../customHooks/useStyleContext';

const CdwList = ({cdwSetting, setSelectedCdw, selectedCdw, onChange}) => {

    const styleContext = useStyleContext()

    const changeCdw = (optionId, quantity) => {
        console.log(selectedCdw)
        let newSelected = selectedCdw?.filter(cdw => cdw?.option_id !== optionId)
        if (quantity > 0){
            newSelected?.push({option_id: optionId, quantity: quantity})
        }
        setSelectedCdw(newSelected)
    }

    return (
        <div>
            <p className={"fs20 py-3 medium"}>Sélectionnez vos garanties complémentaires</p>
            <Row>
                <Col xs={12}>
                    <div className={"border bg-white p-3"}>
                        <p className={"text-center medium m-4"}>Votre franchise actuelle s’élève à {cdwSetting?.normal_amount} {styleContext?.currency?.symbol ?? ''}. Réduisez-là avec ces protections </p>
                        <hr />
                        <Row>
                            <Col xs={6}>
                                <div>
                                    <span className={"fs18 medium"}>{cdwSetting?.cdw_option?.name ?? ""}</span>
                                </div>
                                {cdwSetting?.cdw_option?.service_provider_price &&
                                    <div className={"mb-1"}>
                                        <span className={"fs14"}>Franchise réduite à {cdwSetting?.cdw_option?.service_provider_price ?? ""}{styleContext?.currency?.symbol ?? ''}</span>
                                    </div>
                                }
                            </Col>
                            <Col xs={6} md={3}>
                                <div className={"h-100 d-flex align-items-center justify-content-end"}>
                                    <span className={"me-1"}>Montant:</span> <span>{cdwSetting?.cdw_option?.default_amount ?? ""} {styleContext?.currency?.symbol ?? ''} {cdwSetting?.cdw_option?.type == "per_day" && "/jour"}</span>
                                </div>
                            </Col>
                            <Col md={3} className={"d-flex justify-content-end align-items-center"}>
                                {(!selectedCdw?.find(cdw => cdw?.option_id === cdwSetting?.cdw_option?.id)?.quantity) || (selectedCdw?.find(cdw => cdw?.option_id === cdwSetting?.cdw_option?.id)?.quantity === 0) ?
                                    <Button
                                        onClick={() => changeCdw(cdwSetting?.cdw_option?.id, 1)}
                                        className={cx("c-main bg-main-light no-border p-2 medium d-flex align-items-center justify-content-center", classes.add_option_btn)}
                                    >
                                        <div>Choisir</div>
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => changeCdw(cdwSetting?.cdw_option?.id, 0)}
                                        className={cx("bg-main c-white p-2 medium d-flex align-items-center justify-content-center", classes.add_option_btn)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} className={"me-1"} />
                                        <span>Enlever</span>
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CdwList;