import React, {useContext} from 'react';
import cx from "classnames"
import Color from 'color'

import "./FilterGroup.scss"
import classes from "./FilterGroup.scss.json"
import { useStyleContext } from '../../../../customHooks/useStyleContext';


const FilterGroupItem = ({filterGroup, selected = false, onClick}) => {

    const styleContext = useStyleContext()
    const mainColorLight = Color(styleContext?.colors?.['c-main']).alpha(0.3)

    const handleFilterGroupClick = () => {
        // if(selected)  { return false }
        onClick && onClick( filterGroup?.id ?? null)
    }
    return (
        <div
            className={cx(classes.filterGroupItem, "d-flex flex-column justify-content-around align-items-center pointer shadow-sm")}
            style={{
                backgroundColor: selected ? mainColorLight.string() : '#FFFFFF',
            }}
            onClick={handleFilterGroupClick}
        >
            {filterGroup?.url && (
                <img className={cx(classes.vehiclePicture)} src={filterGroup.url} alt={"image"} />
            )}
            <p className={cx("fs14 medium text-center", {"mt-2": filterGroup?.url})}>{filterGroup?.name ?? 'Tous les véhicules'}</p>
        </div>
    );
};

export default FilterGroupItem;
