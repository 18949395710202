import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import VehiclePicture from "./VehiclePicture";
import "./VehiclePictureCarousel.scss";
import carouselClass from "./VehiclePictureCarousel.scss.json";
import cx from "classnames";

const VehiclePictureCarousel = ({id, pictures, height, vehicleType}) => {
    return (
        <Carousel
            style={{width: '100%', aspectRatio: '4/3', borderRadius: '5px'}}
            interval={null}
            prevIcon={pictures.length > 1 ? <span className={cx(carouselClass.arrow, carouselClass.left, 'bg-light')} /> : null}
            nextIcon={pictures.length > 1 ? <span className={cx(carouselClass.arrow, carouselClass.right, 'bg-light')} /> : null}
        >
            {pictures?.map((picture, index) => {
                return <Carousel.Item className='bradius-5' key={`picture_carousel_item_${id}_${index}`}>
                    <VehiclePicture
                        url={picture?.url ?? picture?.large_url}
                        vehicleType={vehicleType}
                        // square
                    />
                </Carousel.Item>
            })}
        </Carousel>

    );
};

export default VehiclePictureCarousel;
